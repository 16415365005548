<div class="left-content">
    <div class="gradient"></div>
    <app-background-image></app-background-image>
    <button backButton mat-icon-button>
        <mat-icon>west</mat-icon>
    </button>
    <div class="logo">
        <app-logo></app-logo>
    </div>
    <a faqButton mat-icon-button>
        <mat-icon svgIcon="questions"></mat-icon>
    </a>
    <app-sticker *ngIf="showSticker" [title]="'login_sticker_message' | locale"></app-sticker>
</div>

<div class="right-content">
  <router-outlet (activate)="onOutletLoaded($event)">
  </router-outlet>
</div>
