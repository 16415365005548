import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DialogComponent } from '../components/dialog/dialog.component';
import { AppPaths } from '../enums/app-paths.enum';
import { AppointmentService } from '../services/appointment.service';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private matDialog: MatDialog,
    private authService: AuthService,
    private appointmentService: AppointmentService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.isAuthenticated()) {
      return true;
    }
    if(this.appointmentService.inAppointment()) return this.router.navigate([AppPaths.APPOINTMENT_END])
    this.matDialog.open(DialogComponent, {
      panelClass: 'custom-dialog-container',
      maxWidth: 350,
      data: {
        title: "expired_session_title",
        message: "expired_session_message",
        canCancel: false,
        actions: [
          {
            message: "permissions_accept",
            action: () => {},
          }
        ]
      }
    });
    this.authService.logout();
    return this.router.createUrlTree([`/${AppPaths.AUTH}`], { queryParams: { returnUrl: state.url } });
  }

}
