<div class="permissions-container">
  <div class="left-content">
    <app-logo></app-logo>
  </div>
  <div class="right-content">
    <app-header></app-header>
    <app-nav-content>
      <ng-container ngProjectAs="[title]">
        <h2 class="patient">
          {{ 'reason_patient' | locale }}
          <span class="patient-name">
            {{ patient?.name}}
          </span>
        </h2>
      </ng-container>
      <ng-container ngProjectAs="[card]">
        <div class="message-container">
          <mat-icon svgIcon="media"></mat-icon>
          <p>
            {{ "permissions_message_first" | locale }}
            <strong>
              {{ 'permissions_message_middle' | locale }}
            </strong>
            {{ 'permissions_message_last' | locale }}
          </p>
          <mat-icon>info</mat-icon>
        </div>
      </ng-container>
    </app-nav-content>
  </div>
</div>
