<div class="left-content">
  <div class="gradient"></div>
  <app-background-image></app-background-image>
  <button backButton mat-icon-button>
      <mat-icon>west</mat-icon>
  </button>
  <div class="logo">
      <app-logo></app-logo>
  </div>
  <a faqButton mat-icon-button>
      <mat-icon svgIcon="questions"></mat-icon>
  </a>
</div>

<div class="right-content">
  <router-outlet></router-outlet>
</div>
