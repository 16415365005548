<app-header></app-header>
<app-nav-content>
  <ng-container ngProjectAs="[card]">
    <app-doctor-info [doctor]="doctor" [specialty]="specialty">
      <ng-container
        *ngIf="!inAppointment"
        ngProjectAs="[action]"
        [ngTemplateOutlet]="markAsFavorite">
      </ng-container>
    </app-doctor-info>
    <form [formGroup]="formGroup">
      <div class="form-content">
        <ng-container *ngFor="let question of questions">
          <ng-container
            [ngTemplateOutletContext]="{
              customQuestion: question,
              formGroup: formGroup
            }"
            [ngTemplateOutlet]="question.textType ? textQuestion : rateQuestion">
          </ng-container>
        </ng-container>
      </div>

      <ng-container
        *ngIf="!inAppointment"
        [ngTemplateOutlet]="markAsFavorite">
      </ng-container>
      <button
        type="submit"
        color="primary"
        mat-flat-button
        (click)="rateCall()"
        [disabled]="!formGroup.valid">
        {{ "continue" | locale }}
        <mat-icon svgIcon="east" class="east"></mat-icon>
      </button>
    </form>
  </ng-container>
</app-nav-content>

<ng-template #markAsFavorite>
  <button
    type="button"
    mat-flat-button
    class="dark-button"
    (click)="isFavorite ? removeFavoriteDoctor($event): addFavoriteDoctor($event)">
    {{ "doctor_detail_add_favorite_button" | locale}}
    <mat-icon
      class="east doctor-score-unfav-button"
      [svgIcon]="isFavorite ? 'fav-heart-filled' : 'fav-heart-outline'">
    </mat-icon>
  </button>
</ng-template>

<ng-template
  #textQuestion
  let-formGroup="formGroup"
  let-customQuestion="customQuestion">
  <label [ngClass]="{'required': hasCustomQuestions}">
    {{ customQuestion.question }}
  </label>
  <mat-form-field color="primary" [formGroup]="formGroup">
    <textarea
      rows="5"
      matInput
      minlength="3"
      maxlength="250"
      [formControlName]="customQuestion.id"
      [placeholder]="'rate_text_placeholder' | locale ">
    </textarea>
    <mat-error *ngIf="formGroup.get(customQuestion.id).hasError('required')">
      {{ "reason_input_required" | locale }}
    </mat-error>
    <mat-error
      *ngIf="formGroup.get(customQuestion.id).hasError('minlength') || formGroup.get(customQuestion.id).hasError('maxlength')">
      {{ "reason_input_length" | locale }}</mat-error>
  </mat-form-field>
</ng-template>

<ng-template #rateQuestion let-formGroup="formGroup" let-customQuestion="customQuestion">
  <div class="radio" [formGroup]="formGroup">
    <label>
      {{ customQuestion.question }}
    </label>
    <mat-radio-group color="primary" #radioGroup="matRadioGroup" [formControlName]="customQuestion.id">
      <mat-radio-button *ngFor="let ratingValue of ratingValues" [value]="ratingValue">
        <mat-icon>
          {{ (radioGroup.value && ratingValue <= radioGroup.value) ? 'star' : 'star_border' }}
        </mat-icon>
      </mat-radio-button>
    </mat-radio-group>
    <mat-divider></mat-divider>
  </div>
</ng-template>
