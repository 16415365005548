import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { OpentokErrors } from 'src/app/enums/opentok-errors.enum';
import { Patient } from 'src/app/models/patient.model';
import { CallService } from 'src/app/services/call.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { OpentokService } from 'src/app/services/opentok.service';

@Component({
  selector: 'app-required-permissions',
  templateUrl: './required-permissions.component.html',
  styleUrls: ['./required-permissions.component.css']
})
export class RequiredPermissionsComponent implements OnInit, OnDestroy {
  patient!: Patient;

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private callService: CallService,
    private opentokService: OpentokService,
    private navigationService: NavigationService
  ) {
    const { caller } = this.callService.callData;
    this.patient = caller;
    this.requestPermissions();
  }

  ngOnInit(): void {
    this.navigationService.hideOptions();
  }

  ngOnDestroy(): void {
    this.navigationService.showOptions();
  }

  requestPermissions(): void {
    this.opentokService.getUserMedia().then((stream: MediaStream) => {
      stream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
      this.ngZone.run(() => this.router.navigate([`/${AppPaths.CALL}`, AppPaths.CALL_WAITING]));
    }).catch((error: Error) => {
      console.error({error});
    });
  }

}
