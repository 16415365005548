import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { catchError, delayWhen, retryWhen, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CallBody } from '../models/call-body.model';
import { CallData } from '../models/call-data.model';
import { CallRateRequest } from '../models/call-rate-request.model';
import { Call } from '../models/call.model';
import { Doctor } from '../models/doctor.model';
import { FileLoadResponse } from '../models/file-load-response.model';
import { Patient } from '../models/patient.model';
import { Specialty } from '../models/specialty.model';
import { RequestService } from './request.service';
import { AppointmentService } from './appointment.service';
import { AmplitudeService } from './amplitude.service';
import { AmplitudeEvents } from '../enums/amplitude-events';

@Injectable({
  providedIn: 'root',
})
export class CallService {
  private callDataSubject: BehaviorSubject<CallData>;
  private timeToRetryInMilliseconds = 30 * 1000;

  set callData(callData: CallData) {
    this.callDataSubject.next(callData);
  }

  get callData(): CallData {
    return this.callDataSubject.value;
  }

  get callData$(): Observable<CallData> {
    return this.callDataSubject.asObservable();
  }

  get doctor(): Doctor | null {
    return this.callData.doctor;
  }

  set doctor(doctor: Doctor | null) {
    this.callData.doctor = doctor;
    this.callDataSubject.next(this.callData);
  }

  get specialty(): Specialty {
    return this.callData.specialty;
  }

  set specialty(specialty: Specialty) {
    this.callData.specialty = specialty;
    this.callDataSubject.next(this.callData);
  }

  get caller(): Patient {
    return this.callData.caller;
  }

  set caller(caller: Patient) {
    this.callData.caller = caller;
    this.callDataSubject.next(this.callData);
  }

  get reason(): string | string[] {
    return this.callData.reason;
  }

  set reason(reason: string | string[]) {
    if (Array.isArray(reason)) {
      reason = reason.join(', ');
    }
    reason = reason.trim();
    this.callData.reason = reason;
    this.callDataSubject.next(this.callData);
  }

  get records(): string[] {
    return this.callData.records;
  }

  set records(records: string[]) {
    this.callData.records = records;
    this.callDataSubject.next(this.callData);
  }

  private callSubject = new BehaviorSubject<Call | null>(null);

  get call$(): Observable<Call | null> {
    return this.callSubject.asObservable();
  }

  get call(): Call | null {
    return this.callSubject.value;
  }

  set call(call: Call | null) {
    localStorage.setItem('call', JSON.stringify(call));
    this.callSubject.next(call);
  }

  getCallId(call: string | Call): string {
    if (typeof call === 'string') {
      return call;
    }
    return call.id;
  }

  constructor(
    private httpClient: HttpClient,
    private requestService: RequestService,
    private amplitudeService: AmplitudeService,
    private appointmentService: AppointmentService
  ) {
    let callData: CallData | string | null = localStorage.getItem('call_data');
    if (callData) {
      callData = JSON.parse(callData);
    } else {
      callData = new CallData();
    }
    const call = localStorage.getItem('call')!;
    this.call = JSON.parse(call);

    this.callDataSubject = new BehaviorSubject<CallData>(callData as CallData);
    this.callData$.subscribe({
      next: (callData: CallData) => {
        localStorage.setItem('call_data', JSON.stringify(callData));
      },
    });

  }

  createCall(localeId: string | null): Observable<Call> {
    if (this.doctor) {
      this.amplitudeService.showEvent(AmplitudeEvents.CALL_DOCTOR_BY_NAME, {
        doctor: `${this.doctor.firstName} ${this.doctor.lastName}`,
      });
      return this.processCall(this.createCallByDoctor(localeId));
    }
    this.amplitudeService.showEvent(AmplitudeEvents.CALL_BY_SPECIALTY, {
      specialty: `${this.specialty.name}`,
    });
    return this.processCall(this.createCallBySpecialty(localeId));
  }

  getCall(id: string): Promise<Call> {
    return this.requestService.get(`/call/${id}`, {});
  }

  getCallUrlPdfs(id: string): Promise<any> {
    return this.requestService.get(`/call/${id}/files`);
  }

  processCall(promise: Observable<Call>): Observable<Call> {
    return promise.pipe(tap((call: Call) => (this.call = call)));
  }

  validateCall(id: string | Call = this.call!) {
    return this.requestService.put(`/call/${this.getCallId(id)}/validate`, {});
  }

  timeoutCall(id: string | Call = this.call!): Promise<Call> {
    return this.requestService.put(`/call/${this.getCallId(id)}/timeout`, {});
  }

  cancelCall(id: string | Call = this.call!): Promise<void> {
    return this.requestService.put(`/call/${this.getCallId(id)}/cancel`, {});
  }

  endCall(
    patientDuration: number,
    connectionLost: boolean,
    call: string | Call = this.call!
  ): Promise<unknown> {
    return this.requestService.put(`/call/${this.getCallId(call)}/end`, {
      patientDuration,
      connectionLost,
    });
  }

  rateCall(
    rateRequest: CallRateRequest,
    id: string | Call = this.call!
  ): Promise<void> {
    if (!rateRequest.questions) {
      return this.requestService.put(
        `/call/${this.getCallId(id)}/rate`,
        rateRequest
      );
    } else {
      return this.rateCustomQuestions(rateRequest);
    }
  }

  endCallFlow(): void {
    this.callData = new CallData();
    this.call = null;
    localStorage.removeItem('call_data');
  }

  private rateCustomQuestions(rateRequest: CallRateRequest): Promise<void> {
    return this.requestService.post(`/satisfactionSurveyAnswers`, rateRequest);
  }

  uploadFile(
    file: FormData,
    id: string | Call = this.call!
  ): Observable<HttpEvent<FileLoadResponse>> {
    return this.httpClient.put<FileLoadResponse>(
      `${environment.API_URL}/call/${this.getCallId(id)}/uploadFile`,
      file,
      { reportProgress: true, observe: 'events' }
    );
  }

  private createCallByDoctor(localeId: string | null): Observable<Call> {
    const {
      caller: { id: patient },
      records,
      reason: motive,
    } = this.callData;

    const doctor = this.doctor!.id;
    const bodyParams: CallBody = {
      patient,
      records,
      motive,
      doctor,
    };

    if (localeId) {
      bodyParams.localeId = localeId;
    }

    if (this.appointmentService.inAppointment()) {
      bodyParams.appointment = this.appointmentService.getAppointmentId()!;
    }

    return this.httpClient
      .post<Call>(`${environment.API_URL}/call`, bodyParams)
      .pipe(
        catchError((error: any, caught: Observable<Call>) => {
          this.handlerError(error);
          if (
            this.appointmentService.inAppointment() &&
            (error.name === 'noAvailableDoctor' || error.name === 'doctorDND')
          ) {
            this.appointmentService.setAppointmentStatus('notAvailableDoctor');
          }
          return caught;
        })
      );
  }

  private createCallBySpecialty(localeId: string | null): Observable<Call> {
    const {
      caller: { id: patient },
      records,
      reason: motive,
      specialty: { realId: specialtyId },
    } = this.callData;

    const bodyParams: CallBody = {
      patient,
      records,
      motive,
    };

    if (localeId) {
      bodyParams.localeId = localeId;
    }

    return this.httpClient
      .post<Call>(
        `${environment.API_URL}/call/specialty/${specialtyId}/random`,
        {
          patient,
          records,
          motive,
        }
      )
      .pipe(
        // Retry when the call is not created
        retryWhen((errors: Observable<HttpErrorResponse>) => {
          return errors.pipe(
            // Wait for 30 seconds
            delayWhen(({ error }: HttpErrorResponse) => {
              this.handlerError(error);
              console.log(`${error.name}: ${error.message}`);
              return timer(this.timeToRetryInMilliseconds);
            }),
            tap(() => console.log('Retrying call creation...'))
          );
        })
      );
  }

  private handlerError(error: any) {
    let eventType: AmplitudeEvents;
    let eventParams: any;

    if (error.name === 'noAvailableDoctor') {
      eventType = AmplitudeEvents.CALL_BY_SPECIALTY_ERROR;
      eventParams = { message: error.message };
    } else if (error.name === 'doctorDND') {
      eventType = AmplitudeEvents.DOCTOR_NOT_AVAILABLE;
      eventParams = {
        doctor: `${this.doctor?.firstName} ${this.doctor?.lastName}`,
        callId: this.call?.id,
      };
    } else {
      eventType = AmplitudeEvents.CALL_ERROR;
      eventParams = {
        error,
        callId: this.call?.id,
      };
    }

    this.amplitudeService.showEvent(eventType, eventParams);
  }
}
