import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as auth from 'auth0-js';
import { RegistrationService } from 'src/app/services/registration.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../dialog/dialog.component';
import { AppPaths } from 'src/app/enums/app-paths.enum';

@Component({
  selector: 'app-auth0-registration',
  templateUrl: './auth0-registration.component.html',
  styleUrls: ['./auth0-registration.component.css']
})
export class Auth0RegistrationComponent implements OnInit {

  auth0: any;
  provider: any;
  patient: any;

  constructor(private registrationService: RegistrationService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.auth0 = this.authService.getAuth0();
    this.provider = this.registrationService.getProvider();
    this.patient = this.registrationService.getPatient();
    if(!this.provider || !this.patient) this.router.navigate([AppPaths.REGISTRATION])

    this.route.fragment.subscribe(async fragment => {
      var params = this.parseParams(fragment);
      if (params.id_token) {
        var validAuthentication = this.authService.validateAuthentication(params.id_token)
        var auth0Body = this.authService.getBodyFromAuth0(params.id_token)
        this.registrationService.updatePatient({email: auth0Body.email})
        if (validAuthentication) {
          this.router.navigate([AppPaths.REGISTRATION, AppPaths.DATA_VALIDATION]);
        } else {
          //show dialog or just avoid? (probably an old id_token)
          this.router.navigate([`/${AppPaths.AUTH}`])
        }
      }
       else if (params.error) {
         this.showRegistrationErrorDialog()
       }
    })
  }

  redirectToSocialLogin(connection:string){
    this.auth0.authorize({
      redirectUri: environment.PWA_URL + '/registration/account',
      connection: connection,
      responseType: 'id_token'
    });
  }

  redirectToMailAndPassword(){
    this.router.navigate([AppPaths.REGISTRATION, AppPaths.EMAIL])
  }

  showRegistrationErrorDialog(){
    this.dialog.open(DialogComponent, {
      width:"80%",
      panelClass: 'custom-dialog-container',
      data: {
        message: "dialog_registration_error_custom",
        title: "dialog_title_warning",
        canCancel: true
      }
    })
  }

  parseParams(fragment: string): any {
    if(!fragment) return {};
    var parts = fragment.split('&');
    var params: any = {};
    parts.forEach(part => {
      const pieces = part.split('=');
      params[pieces[0]] = pieces[1];
    });
    return params;
  }

}
