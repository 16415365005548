<mat-list>
  <ng-container *ngFor="let schedule of schedules">
    <mat-list-item
      *ngFor="let hour of schedule.hours; first as isFirst, last as isLast"
      [ngClass]="{
        'last-schedule': isLast,
        'doctor-schedule': areDoctorSchedules
      }">
        <p matLine [ngClass]="{'day-name': isFirst}">
          <ng-container *ngIf="isFirst">
            {{ schedule.dayName }}
          </ng-container>
          <span>
            {{ hour.start }} {{ "schedules_to" | locale }} {{ hour.end }} {{ "schedules_hours_abbreviation" | locale}}
          </span>
        </p>
    </mat-list-item>
  </ng-container>
</mat-list>
