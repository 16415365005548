import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import * as Rollbar from "rollbar";
import { Observable, throwError } from "rxjs";
import { catchError, delay, retry } from "rxjs/operators";
import { ROLLBAR_CONFIG_TOKEN } from "../tokens/rollbar-config.token";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(@Inject(ROLLBAR_CONFIG_TOKEN) private rollbar: Rollbar) {}


  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          let messageError: string;
          if (error.error instanceof ErrorEvent) {
            // client-side error
            messageError = error.error.message;
          } else {
            // server-side error
            const message = error.error.msg ? error.error.msg : error.message;
            messageError = `Code: ${error.status}, ${message}`;
          }
          if(messageError) {
            this.rollbar.error(new Error(messageError).stack);
          }
          return throwError(error);
        })
      );
  }
}
