import { Component, HostBinding, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { DialogComponent } from '../../dialog/dialog.component';
import { PatientService } from 'src/app/services/patient.service';
import { Patient } from 'src/app/models/patient.model';
import { AmplitudeEvents } from 'src/app/enums/amplitude-events';

@Component({
  selector: 'app-auth0-social-login',
  templateUrl: './auth0-social-login.component.html',
  styleUrls: ['./auth0-social-login.component.css']
})
export class Auth0SocialLoginComponent implements OnInit {
  auth0:any;
  registrationPath = AppPaths.REGISTRATION

  constructor(private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    public amplitudeService: AmplitudeService,
    private patientService: PatientService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.route.fragment.subscribe(async fragment => {
      var params = this.parseParams(fragment);
      if (params.id_token) {
        var validAuthentication = this.authService.validateAuthentication(params.id_token)
        if (validAuthentication) {
          try {
            await this.authService.handleAuth0Login(params.id_token);
            this.patientService.getMe().then((me: Patient) => {
              this.amplitudeService.setUser(me.id);
              this.amplitudeService.showEvent(AmplitudeEvents.LOGIN_AUTH0,{});
            });
            this.router.navigate([AppPaths.TERMS_AND_CONDITIONS]);
          } catch(err:any) {
            if (err.status == 409){
              this.amplitudeService.showEvent(AmplitudeEvents.USER_NOT_ACTIVE,{});
              this.router.navigate([AppPaths.CREDENTIAL_VALIDATION])
            } else {
              localStorage.clear();
              this.showNoUserDialog()
            }
          }
        } else {
          //show dialog or just avoid? (probably an old id_token)
        }
      } else if (params.error) {
        this.showLoginErrorDialog()
      }
    })
  }

  parseParams(fragment: string): any {
    if(!fragment) return {};
    var parts = fragment.split('&');
    var params: any = {};
    parts.forEach(part => {
      const pieces = part.split('=');
      params[pieces[0]] = pieces[1];
    });
    return params;
  }

  redirectToSocialLogin(connection:string){
    this.auth0.authorize({
      redirectUri: environment.PWA_URL + '/social',
      connection: connection,
      responseType: 'id_token'
    });
  }

  redirectToMailAndPassword(){
    this.router.navigate([AppPaths.SOCIAL, 'email'])
  }

  showNoUserDialog(){
    this.dialog.open(DialogComponent, {
      width:"80%",
      panelClass: 'custom-dialog-container',
      data: {
        message: "dialog_login_error_no_user",
        title: "dialog_title_warning",
        canCancel: true
      }
    })
  }

  showLoginErrorDialog(){
    this.dialog.open(DialogComponent, {
      width:"80%",
      panelClass: 'custom-dialog-container',
      data: {
        message: "dialog_login_error_custom",
        title: "dialog_title_warning",
        canCancel: true
      }
    })
  }

}
