import { Component, OnInit } from '@angular/core';
import { RegistrationService } from 'src/app/services/registration.service';
import { environment } from 'src/environments/environment';

import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { DialogComponent } from '../../dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';

@Component({
  selector: 'app-auth0-email-registration',
  templateUrl: './auth0-email-registration.component.html',
  styleUrls: ['./auth0-email-registration.component.css']
})

export class Auth0EmailRegistrationComponent implements OnInit {

  hidePassword = true;

  provider: any;
  patient: any;

  auth0: any;

  continueDisabled = false;

  registrationFormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  })

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private authService: AuthService,
    private spinnerService: LoadingSpinnerService,
    private registrationService: RegistrationService,
  ) { }

  ngOnInit(): void {
    this.auth0 = this.authService.getAuth0();
    this.provider = this.registrationService.getProvider();
    this.patient = this.registrationService.getPatient();
    if (!this.provider || !this.patient) this.router.navigate([AppPaths.REGISTRATION])
    this.registrationFormGroup.patchValue({ email: this.patient.email })
  }

  login() {
    this.continueDisabled = true;
    var self = this;
    this.spinnerService.toggleSpinner();
    this.auth0.signup({
      client_id: environment.AUTH0_CLIENTID,
      email: this.registrationFormGroup.value.email,
      password: this.registrationFormGroup.value.password,
      connection: environment.AUTH0_REALM,
      username: this.patient.name,
      name: this.patient.name
    }, (err: any, authResult: any) => {
      self.spinnerService.toggleSpinner();
      if (err) {
        var userExists = err.code == "user_exists";
        this.showRegistrationError(userExists)
        this.continueDisabled = false;
      } else {
        self.spinnerService.toggleSpinner();
        this.auth0.client.login({
          realm: environment.AUTH0_REALM,
          username: this.registrationFormGroup.value.email,
          password: this.registrationFormGroup.value.password,
          scope: environment.AUTH0_SCOPE
        }, async function (err: any, authResult: any) {
          if (err) {
            self.showRegistrationError(false)
            self.continueDisabled = false;
          } else {
            self.authService.handleAuth0Registration(authResult.idToken);
            self.registrationService.updatePatient({email: self.registrationFormGroup.value.email})
            self.router.navigate([AppPaths.REGISTRATION, AppPaths.DATA_VALIDATION])
          }
          self.spinnerService.toggleSpinner();
        });
      }
    })
  }

  showRegistrationError(userExists: boolean) {
    var message = userExists ? "dialog_registration_email_already_exists" : "dialog_registration_email__error_custom";
    this.dialog.open(DialogComponent, {
      width: "80%",
      panelClass: 'custom-dialog-container',
      data: {
        message: message,
        title: "dialog_title_warning",
        canCancel: true
      }
    })
  }
}
