import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  showMenu$: Observable<boolean> = this.navigationService.menu$;

  logoWidth = 110;

  menuOptions = [
    {
      img: 'home',
      text: 'Inicio',
      path: AppPaths.HOME
    },
    {
      img: 'doctors',
      text: 'Doctores',
      path: AppPaths.DOCTORS
    },
    {
      img: 'calls',
      text: 'Mis llamadas',
      path: AppPaths.CALLS_HISTORY
    }
  ]
  selected: number = 0;

  constructor(private navigationService: NavigationService,
    private router: Router,
    private cd: ChangeDetectorRef) {
    this.router.events.subscribe(data => {
      if (data instanceof NavigationEnd) {
        this.selectIcon(data.url)
      }
    })
  }

  ngOnInit() {
    this.cd.detectChanges();
  }

  select(itemIndex: number) {
    this.selected = itemIndex;
    let path = this.menuOptions[this.selected].path;
    if (path != "")
      this.router.navigate([`/${AppPaths.NAVBAR}`, path]);
    else {
      this.router.navigate([`/${AppPaths.NAVBAR}`]);
    }
  }
  selectIcon(url: string): void {
    if (url.includes(AppPaths.CALLS_HISTORY) || url.includes(AppPaths.CALL_DETAIL) || url.includes(AppPaths.DOWNLOAD)) {
      this.selected = 2;
    }
    else if (url.includes(AppPaths.DOCTORS)) {
      this.selected = 1;
    }
    else {
      this.selected = 0;
    }
  }

}
