<app-nav-content *ngIf="call">
    <h1 title>
      {{'call_detail_title' |locale}}
    </h1>

    <ng-container ngProjectAs="[card]">
        <div class="call-container">
            <div class="doctor-review">
                <div class="doctor-name">
                    {{call.doctor.firstName}}
                    {{call.doctor.lastName}}
                </div>
                <div class="specialty">
                    <p>{{ call.specialty | localizedName }}</p>
                </div>
            </div>
            <div class="call-info-container">
                <div class="datetime">
                    <mat-label>{{"call_detail_date" | locale}}</mat-label>
                    <div class="item-description">
                        <h4 class="item-text">{{call.createdAt|date:'shortDate'}}</h4>
                        <h4 class="hour">{{call.createdAt|date:'shortTime'}}</h4>
                    </div>
                    <mat-divider></mat-divider>
                </div>
                <div class="motive">
                    <mat-label>{{"call_detail_motive" | locale}}</mat-label>
                    <div class="item-description">
                        <h4 class="item-text">{{call.motive}}</h4>
                    </div>
                    <mat-divider></mat-divider>
                </div>
                <div class="medical-history">
                    <mat-label>{{"call_detail_records" | locale}}</mat-label>
                    <div class="item-text" *ngFor="let record of medicalRecords">{{getRecordLocalized(record)}}</div>
                    <mat-divider></mat-divider>
                </div>
                <div class="diagnosis">
                    <mat-label>{{"call_detail_diagnoses" | locale}}</mat-label>
                    <div class="item-description">
                        <h4 class="item-text">{{diagnosis}}</h4>
                    </div>
                    <mat-divider></mat-divider>
                </div>
                <div class="resolution">
                    <mat-label>{{"call_detail_resolution" | locale}}</mat-label>
                    <div class="item-description">
                        <h4 class="item-text">{{resolution|locale}}</h4>
                    </div>
                </div>
            </div>
        </div>
        <button mat-flat-button color="primary"
            *ngIf="call.prescriptions.length > 0 || call.medicalOrders.length > 0 || call.medicalCareRecord"
            [routerLink]="[downloadPath, call.id]">
            {{ "call_detail_files" | locale}}
            <mat-icon svgIcon="east" class="east"></mat-icon>
        </button>
    </ng-container>
</app-nav-content>
