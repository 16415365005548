import { Injectable } from '@angular/core';
import { SpecialtyConfig } from '../models/specialty-config.model';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  constructor(private requestService: RequestService) { }

  getById(providerId: string){
    return this.requestService.get('/provider/' + providerId)
  }
  getBasicRegistrationInfo(){
    return this.requestService.get('/provider/basicRegistrationInfo')
  }
  getTermsAndConditions(providerId:string){
    return this.requestService.get('/provider/' + providerId + '/tyc')
  }

  getSatisfactionSurveyQuestions(providerId: string): Promise<any> {
    return this.requestService.get(`/provider/${providerId}/satisfactorySurveyQuestions`)
  }

  getProviderSpecialtyConfig(providerId: string): Promise<SpecialtyConfig[]> {
    return this.requestService.get(`/specialtyProviderConfig/provider/${providerId}`)
  }
}
