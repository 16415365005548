import { Injectable } from '@angular/core';
import {
  getUserMedia,
  GetUserMediaProperties,
  initSession,
  Event,
  Publisher,
  Stream,
  Session,
  OTError,
  Subscriber,
  Connection,
  SubscriberProperties,
  PublisherProperties,
} from '@opentok/client';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DisconnectReasons } from '../enums/disconnect-reasons.enum';
import { Signals } from '../enums/signals.enum';
import { States } from '../enums/states.enum';
import { CallEvent } from '../models/call-event.model';

@Injectable({
  providedIn: 'root',
})
export class OpentokService {
  opentokApiKey = environment.OPENTOK_API_KEY;
  private connectedNumber = 0;
  private session!: Session;
  private subscriber!: Subscriber | null;
  private publisher!: Publisher | null;
  private properties: SubscriberProperties | PublisherProperties = {
    insertMode: 'append',
    width: '100%',
    height: '100%',
    style: {
      audioLevelDisplayMode: States.OFF,
      buttonDisplayMode: States.OFF,
    },
  };

  private microphoneSubject = new BehaviorSubject<boolean>(true);
  get microphone$(): Observable<boolean> {
    return this.microphoneSubject.asObservable();
  }
  get microphone(): boolean {
    return this.microphoneSubject.getValue();
  }

  private cameraSubject = new BehaviorSubject<boolean>(true);
  get camera$(): Observable<boolean> {
    return this.cameraSubject.asObservable();
  }
  get camera(): boolean {
    return this.cameraSubject.getValue();
  }

  private eventSubject = new Subject<CallEvent>();

  get event$() {
    return this.eventSubject.asObservable();
  }

  set event(event: CallEvent) {
    this.eventSubject.next(event);
  }

  constructor() {}

  initializeSession(
    apiKey: string,
    sessionId: string,
    token: string
  ): Promise<Session> {
    apiKey = apiKey || this.opentokApiKey;
    this.session = initSession(apiKey, sessionId);

    this.session.on(
      {
        streamCreated: this.streamCreatedListener,
        connectionCreated: this.connectionCreatedListener,
        connectionDestroyed: this.connectionDestroyedListener,
        sessionDisconnected: this.sessionDisconnectedListener,
      },
      this
    );
    this.session.on('signal', async (event: any) => {
      if (event.type === "signal:" + Signals.DOCTOR_ID) {
        this.event = {
          signal: Signals.DOCTOR_ID,
          data: {
            id: event.data
          },
        };
      }
    });

    return this.sessionConnect(token);
  }

  public sessionConnect(opentokToken: string): Promise<Session> {
    return new Promise<Session>((resolve, reject) => {
      this.session?.connect(opentokToken, (error) => {
        if (error) {
          reject(error);
        } else {
          resolve(this.session);
        }
      });
    });
  }

  streamCreatedListener(
    event: Event<'streamCreated', Publisher> & {
      stream: Stream;
    }
  ) {
    if (!this.subscriber) {
      this.subscriber = this.session.subscribe(
        event.stream,
        'subscriber',
        this.properties,
        this.handleError
      );
    }
    if (!this.publisher) {
      this.publisher = this.session.publish(
        'publisher',
        this.properties,
        this.handleError
      );
      this.publisher.publishAudio(this.microphone);
      this.publisher.publishVideo(this.camera);
    }
  }

  connectionCreatedListener(
    _event: Event<'connectionCreated', Session> & {
      connection: Connection;
    }
  ) {
    console.log('Connection created');
    this.connectedNumber++;
    if (this.connectedNumber > 1 && !this.publisher) {
      this.event = { signal: Signals.CALL_STARTED };
    }
  }

  connectionDestroyedListener(
    event: Event<'connectionDestroyed', Session> & {
      connection: Connection;
      reason: string;
    }
  ) {
    this.connectedNumber--;
    if (
      this.connectedNumber <= 1 &&
      event.connection.connectionId !==
        this.publisher?.stream?.connection.connectionId
    ) {
      this.event = {
        signal: Signals.CALL_ENDED,
        data: {
          connectionLost:
            event.reason === DisconnectReasons.NETWORK_DISCONNECTED,
        },
      };
    }
  }

  sessionDisconnectedListener(
    event: Event<'sessionDisconnected', Session> & {
      reason: string;
    }
  ): void {
    if (this.publisher) {
      this.session.unpublish(this.publisher);
      this.publisher.destroy();
      this.publisher = null;
      this.subscriber = null;
    }
  }

  switchCamera(): void {
    if (this.publisher) {
      this.publisher
        .cycleVideo()
        .then((data) => {
          console.log(`Switched to: ${data.deviceId}`);
        })
        .catch((error) => {
          console.log(error);
        });
      this.sendSignal(Signals.CAMERA_SWITCHED);
    }
  }

  getUserMedia(constraints?: GetUserMediaProperties): Promise<MediaStream> {
    return getUserMedia(constraints);
  }

  sendSignal(type: string, data?: string, to?: Connection) {
    const signal: { type: string; data?: string; to?: Connection } = {
      type,
    };
    if (this.subscriber || to) {
      signal.to = to ? to : this.subscriber?.stream?.connection;
    }
    if (data) {
      signal.data = data;
    }

    if (this.session?.connection) {
      this.session.signal(signal, (error?: OTError) => {
        if (error) {
          this.handleError(error);
        } else {
          console.log(`Signal sent -> ${type}`);
        }
      });
    }
  }

  public toggleMicrophone(): boolean {
    this.microphoneSubject.next(!this.microphone);
    if (this.publisher) {
      this.publisher.publishAudio(this.microphone);
      this.sendSignal(
        Signals.MICROPHONE_TOGGLED,
        this.microphone ? States.ON : States.OFF
      );
    }
    return this.microphone;
  }

  public toggleCamera(): boolean {
    this.cameraSubject.next(!this.camera);
    if (this.publisher) {
      this.publisher.publishVideo(this.camera);
      this.sendSignal(
        Signals.CAMERA_TOGGLED,
        this.camera ? States.ON : States.OFF
      );
    }
    return this.camera;
  }

  public disconnect(): void {
    if (this.session?.connection) {
      this.connectedNumber = 0;
      this.cameraSubject.next(true);
      this.microphoneSubject.next(true);
      this.publisher = null;
      this.subscriber = null;

      this.sendSignal(Signals.NOTIFICATION, 'ON_HANG_UP');

      this.session.off({
        streamCreated: this.streamCreatedListener,
        connectionCreated: this.connectionCreatedListener,
        connectionDestroyed: this.connectionDestroyedListener,
        sessionDisconnected: this.sessionDisconnectedListener,
      });
      this.session.disconnect();
    }
  }

  public handleError(error?: OTError) {
    if (error) {
      const { name, message } = error;
      console.error(`${name}: ${message}`, { error });
    }
  }
}
