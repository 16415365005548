import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { LocalePipe } from 'src/app/locale.pipe';
import { CallRateRequest } from 'src/app/models/call-rate-request.model';
import { CustomQuestion } from 'src/app/models/custom-question.model';
import { Doctor } from 'src/app/models/doctor.model';
import { Specialty } from 'src/app/models/specialty.model';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { AppointmentService } from 'src/app/services/appointment.service';
import { CallService } from 'src/app/services/call.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { PatientService } from 'src/app/services/patient.service';
import { ProviderService } from 'src/app/services/provider.service';
import { AmplitudeEvents } from 'src/app/enums/amplitude-events';

@Component({
  selector: 'app-rate-call',
  templateUrl: './rate-call.component.html',
  styleUrls: ['./rate-call.component.css'],
  providers: [LocalePipe],
})
export class RateCallComponent implements OnInit, OnDestroy {
  doctor: Doctor;
  specialty: Specialty;
  hasCustomQuestions = false;
  questions: CustomQuestion[] = [];
  ratingValues = [1, 2, 3, 4, 5];
  formGroup!: FormGroup;
  isFavorite: boolean = false;
  inAppointment: boolean = false;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private localePipe: LocalePipe,
    private callService: CallService,
    private formBuilder: FormBuilder,
    private patientService: PatientService,
    private providerService: ProviderService,
    private navigationService: NavigationService,
    public amplitudeService: AmplitudeService,
    private appointmentService: AppointmentService
  ) {
    const { doctor, specialty, call } = this.callService;
    this.doctor = doctor! || call?.doctor;
    this.specialty = doctor
      ? doctor.mainSpecialty
      : specialty || call!.specialty;
  }

  async ngOnInit(): Promise<void> {
    this.amplitudeService.showEvent(
      AmplitudeEvents.PATIENT_QUALIFYING_CALL,
      {}
    );
    this.formGroup = this.formBuilder.group(
      {},
      { validators: this.hasCustomQuestions ? [] : [this.bothRatesValidator()] }
    );
    this.navigationService.hideBackButton();
    this.inAppointment = this.appointmentService.inAppointment();
    if (this.inAppointment) {
      this.navigationService.hideMenu();
      this.navigationService.hideOptions();
    }
    const provider = await this.patientService.getProvider();
    let favouritedDoctors = await this.patientService.getFavouritedDoctors();
    this.isFavorite = favouritedDoctors.some((favouritedDoctor: { id: any }) =>
      this.doctor ? this.doctor.id == favouritedDoctor.id : false
    );
    this.hasCustomQuestions = provider.supportsCustomQuestions;

    if (this.hasCustomQuestions) {
      this.providerService
        .getSatisfactionSurveyQuestions(provider.id)
        .then((questions: any[]) => {
          this.questions = questions;
          this.addControlsInForm();
        });
    } else {
      this.httpClient
        .get<CustomQuestion[]>('assets/data/default-questions.data.json')
        .pipe(
          map((questions: CustomQuestion[]) => {
            return questions.map((question) => {
              question.question = this.localePipe.transform(question.question);
              return question;
            });
          })
        )
        .subscribe((questions: CustomQuestion[]) => {
          this.questions = questions;
          this.addControlsInForm();
        });
    }
  }

  addControlsInForm(): void {
    this.questions.forEach((question) => {
      this.formGroup.addControl(
        question.id,
        this.formBuilder.control(
          null,
          this.hasCustomQuestions ? [Validators.required] : []
        )
      );
    });
  }

  ngOnDestroy(): void {
    this.navigationService.showBackButton();
  }

  bothRatesValidator(): ValidatorFn {
    return ({
      value: { patientStars, doctorReputation },
    }: AbstractControl): ValidationErrors | null => {
      if (
        (patientStars && !doctorReputation) ||
        (!patientStars && doctorReputation)
      ) {
        return { bothRatesValidator: true };
      }
      return null;
    };
  }

  rateCall() {
    if (this.formGroup.valid && !this.formGroup.pristine) {
      let callRateRequest: CallRateRequest;
      if (this.hasCustomQuestions) {
        callRateRequest = {
          questions: [],
          answers: [],
          call: this.callService.call!.id,
        };
        this.questions.forEach((question) => {
          callRateRequest.questions!.push(question.question);
          callRateRequest.answers!.push(this.formGroup.get(question.id)!.value);
        });
      } else {
        callRateRequest = this.formGroup.value;
      }
      this.callService
        .rateCall(callRateRequest)
        .then(() => {
          this.amplitudeService.showEvent(
            AmplitudeEvents.PATIENT_CALL_QUALIFY,
            {}
          );
          this.leaveCallFlow();
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (this.formGroup.pristine) {
      this.leaveCallFlow();
    }
  }

  leaveCallFlow(): void {
    this.callService.endCallFlow();
    if(this.inAppointment) {
      this.router.navigate([AppPaths.APPOINTMENT_END]);
    }
    this.router.navigate([`/${AppPaths.CALL}`, AppPaths.CALL_ENDED]);
  }

  async addFavoriteDoctor(event: any) {
    event.preventDefault();
    event.stopPropagation();
    if (this.doctor) {
      this.isFavorite = !this.isFavorite;
      await this.patientService.addDoctorToFavourites(this.doctor.id);
      this.amplitudeService.showEvent(AmplitudeEvents.PATIENT_FAV_DOCTOR, {
        doctor: `${this.doctor.firstName} ${this.doctor.lastName}`,
        doctorId: this.doctor.id,
      });
    }
  }

  async removeFavoriteDoctor(event: any) {
    event.preventDefault();
    event.stopPropagation();
    if (this.doctor) {
      this.isFavorite = !this.isFavorite;
      await this.patientService.removeDoctorFromFavourites(this.doctor.id);
      this.amplitudeService.showEvent(
        AmplitudeEvents.PATIENT_REMOVE_FAV_DOCTOR,
        {
          doctor: `${this.doctor.firstName} ${this.doctor.lastName}`,
          doctorId: this.doctor.id,
        }
      );
    }
  }
}
