import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ProviderService } from 'src/app/services/provider.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { RegistrationService } from 'src/app/services/registration.service';
import { Country } from 'src/app/models/country.model';
import { CountryService } from 'src/app/services/country.service';
import { AppPaths } from 'src/app/enums/app-paths.enum';

@Component({
  selector: 'app-provider-select',
  templateUrl: './provider-select.component.html',
  styleUrls: ['./provider-select.component.css'],
})
export class ProviderSelectComponent implements OnInit {

  selectProviderFormGroup: FormGroup;

  providers: any;
  countryProviders!: any[];
  filteredOptions: Observable<any[]> = new Observable();
  countries: Country[] = [];
  defaultImage = "" //LAD LOGO?

  constructor(
    private router: Router,
    private countryService: CountryService,
    private providerService: ProviderService,
    private registrationService: RegistrationService,
  ) {
    this.selectProviderFormGroup = new FormGroup({
      country: new FormControl('', [Validators.required]),
      provider: new FormControl('', [Validators.required, this.validateSelectedProvider.bind(this)])
    })
  }

  async ngOnInit(): Promise<void> {

    this.providers = await this.providerService.getBasicRegistrationInfo();
    this.countries = await this.countryService.getCountries();

    this.selectProviderFormGroup.get('country')!.valueChanges
    .subscribe((country: any) => {
        this.countryProviders = this.providers.filter(({ country: providerCountry }: any) => providerCountry && providerCountry == country!.id)
        this.selectProviderFormGroup.get('provider')!.reset();
      })

    this.filteredOptions = this.selectProviderFormGroup.get('provider')!.valueChanges
    .pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      map((value: any) => this._filter(value))
    )
  }

  private _filter(value: string): string[] {
    const opts = typeof(value) == "string" ? value.toLowerCase().split(" ") : '';
    if(!this.countryProviders) return [];
    return this.countryProviders.filter((provider: { name: string; country: Country }) => {
      for (let i = 0; i < opts.length; i++) {
        let filterValue = opts[i].trim();
        if (!provider.name.toLowerCase().includes(filterValue))
          return false;
      }
      return true;
    });
  }

  displayFn(option: { name: string; }): string {
    return option?.name || '';
  }

  validateSelectedProvider(): ValidationErrors | null {
    const selectedProvider = this.selectProviderFormGroup?.get('provider')?.value;
    const selectedCountry = this.selectProviderFormGroup?.get('country')?.value;
    if (!selectedProvider || typeof selectedProvider === 'string') {
      return { invalidProvider: true };
    }
    const indexOf = this.countryProviders.findIndex((provider: any) => provider.id === selectedProvider.id && selectedProvider.country === selectedCountry.id);
    if (indexOf == -1) {
      return { invalidProvider: true };
    };
    return null;
  }

  nextStep(){
    const selectedProvider = this.selectProviderFormGroup.get('provider')!.value;
    this.registrationService.setProvider(selectedProvider)
    if (selectedProvider.useToken) {
      this.router.navigate([AppPaths.REGISTRATION, AppPaths.TOKEN_INCOME])
    } else {
      this.router.navigate([AppPaths.REGISTRATION, AppPaths.VALIDATION])
    }
  }

}
