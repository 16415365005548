<app-nav-content [showConfettiInSmallScreen]="false">
  <ng-container ngProjectAs="[title]">
    <app-stepper
      [numberOfSteps]="steps"
      [currentStepIndex]="currentStepIndex">
    </app-stepper>
    <h2 class="patient">
      {{ 'reason_patient' | locale }}
      <span class="patient-name">
        {{ patient?.name}}
      </span>
    </h2>
    <h1>
      {{ 'reason_title' | locale}}
    </h1>
  </ng-container>
  <ng-container ngProjectAs="[card]">
    <app-stepper
      [numberOfSteps]="steps"
      [currentStepIndex]="currentStepIndex">
    </app-stepper>
    <mat-selection-list
      #reasonsList
      color="primary"
      [ngClass]="{'show-input': showInput}"
      (selectionChange)="selectionChange($event)">
      <mat-list-option
        [value]="reason"
        [ngClass]="{ 'last-reason': last }"
        *ngFor="let reason of reasons; last as last">
        <p mat-line>
          {{ reason.reasonLocaleKey | locale }}
        </p>
      </mat-list-option>
    </mat-selection-list>
    <div class="shadow-box" *ngIf="showInput">
      <mat-form-field>
        <textarea
          matInput
          rows="2"
          type="text"
          maxlength="250"
          [formControl]="otherReasonControl"
          [placeholder]="'reason_wrote_the_reason' | locale">
        </textarea>
        <mat-error *ngIf="otherReasonControl.hasError('required')">{{ "reason_input_required" | locale }}</mat-error>
        <mat-error *ngIf="otherReasonControl.hasError('minlength') || otherReasonControl.hasError('maxlength')">{{ "reason_input_length" | locale }}</mat-error>
      </mat-form-field>
    </div>
    <button
      mat-flat-button
      color="primary"
      (click)="goToNextStep()"
      [disabled]="!reasonsList.selectedOptions.hasValue() || (otherReasonControl.invalid && showInput)">
      {{ 'continue' | locale}}
      <mat-icon svgIcon="east" class="east"></mat-icon>
    </button>
  </ng-container>
</app-nav-content>
