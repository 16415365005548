import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { AppPaths } from '../enums/app-paths.enum';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../components/dialog/dialog.component';
import { AppointmentService } from '../services/appointment.service';
import { LocaleService } from '../services/locale.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private matDialog: MatDialog,
    private authService: AuthService,
    private localeService: LocaleService,
    private appointmentService: AppointmentService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    request = this.addHeaders(request);
    return next.handle(request).pipe(
      catchError((error) => {
        if (!this.authService.isAuthenticated() && error.status === 401) {
          if(this.appointmentService.inAppointment()){
            this.router.navigate([AppPaths.APPOINTMENT_END])
            return throwError(error);
          }
          this.matDialog.open(DialogComponent, {
            panelClass: 'custom-dialog-container',
            width: '80%',
            maxWidth: '350px',
            data: {
              title: 'expired_session_title',
              message: 'expired_session_message',
              canCancel: false,
              actions: [
                {
                  message: 'permissions_accept',
                  action: () => {
                    this.authService.logout();
                    this.router.navigate([`/${AppPaths.AUTH}`]);
                  },
                },
              ],
            },
          });
        }
        return throwError(error);
      })
    );
  }

  private addHeaders(request: HttpRequest<any>) {
    const token = this.authService.getToken();
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    const locale = this.localeService.getHeaderLocale();
    if (locale) {
      request = request.clone({
        setHeaders: {
          'Accept-Language': locale,
        },
      });
    }
    return request;
  }
}
