import { Component, NgZone, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { OpentokErrors } from 'src/app/enums/opentok-errors.enum';
import { Doctor } from 'src/app/models/doctor.model';
import { FamilyGroup } from 'src/app/models/family-group.model';
import { Patient } from 'src/app/models/patient.model';
import { Specialty } from 'src/app/models/specialty.model';
import { AppointmentService } from 'src/app/services/appointment.service';
import { CallService } from 'src/app/services/call.service';
import { LocaleService } from 'src/app/services/locale.service';
import { OpentokService } from 'src/app/services/opentok.service';
import { PatientService } from 'src/app/services/patient.service';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { AppointmentCountdownComponent } from '../appointment-countdown/appointment-countdown.component';
import { NavigationService } from 'src/app/services/navigation.service';
import { DoctorNotAvailableComponent } from '../doctor-not-available/doctor-not-available.component';
import { DoctorService } from 'src/app/services/doctor.service';

@Component({
  selector: 'app-call-suggestions',
  templateUrl: './call-suggestions.component.html',
  styleUrls: ['./call-suggestions.component.css'],
})
export class CallSuggestionsComponent implements OnInit {
  patient!: Patient;
  specialty!: Specialty;
  doctor!: Doctor;
  steps!: number;
  currentStepIndex!: number;
  locale: string;
  checkAppointmentStatusTimeout: any;

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private dialog: MatDialog,
    private callService: CallService,
    private localeService: LocaleService,
    private doctorService: DoctorService,
    private patientService: PatientService,
    private opentokService: OpentokService,
    private appointmentService: AppointmentService,
    private navigationService: NavigationService
  ) {
    const { specialty, caller, doctor } = this.callService.callData;
    if (!specialty && !doctor) {
      this.router.navigate([`/${AppPaths.NAVBAR}`]);
    } else if (!caller) {
      this.router.navigate([`/${AppPaths.NAVBAR}`, AppPaths.FAMILY_GROUP]);
    } else if (doctor) {
      this.doctor = doctor;
    } else {
      this.specialty = specialty;
    }
    this.patient = caller;
    this.locale = this.localeService.getLocale();
  }

  ngOnInit(): void {
    this.navigationService.hideMenu();
    this.navigationService.hideOptions();
    var appointmentId = this.appointmentService.getAppointmentId();
    if (appointmentId) {
      this.checkAppointmentStatus(appointmentId);
    }
    this.patientService
      .getFamilyGroup()
      .then(async ({ members }: FamilyGroup) => {
        const hasFamilyGroup = members.length > 1;
        this.steps = hasFamilyGroup ? 4 : 3;
        this.currentStepIndex = hasFamilyGroup ? 3 : 2;
        const provider = await this.patientService.getProvider();
        if (provider.requiresHealthInsuranceInformation) {
          this.steps++;
          this.currentStepIndex++;
        }
      });
  }

  async goToCall(): Promise<void> {
    if (!this.doctor) {
      this.checkMediaPermissions();
    } else {
      const doctor = await this.doctorService.getDoctor(this.doctor.id);
      if (
        !doctor.available ||
        !doctor.canCall ||
        !doctor.hasDevice ||
        doctor.banned ||
        doctor.doNotDisturb
      ) {
        this.dialog.open(DoctorNotAvailableComponent, {
          panelClass: 'custom-dialog-container',
          maxWidth: 350,
        });
      } else {
        this.checkMediaPermissions();
      }
    }
  }

  checkMediaPermissions(): void {
    this.opentokService
      .getUserMedia()
      .then((mediaStream: MediaStream) => {
        mediaStream.getTracks().forEach((track) => track.stop());
        this.redirectTo(AppPaths.CALL_WAITING);
      })
      .catch((error: Error) => {
        if (error.name === OpentokErrors.USER_MEDIA_ACCESS_DENIED) {
          this.redirectTo(AppPaths.CALL_PERMISSIONS);
        }
      });
  }

  checkAppointmentStatus(appointmentId: string): void {
    this.checkAppointmentStatusTimeout = undefined;
    try {
      this.appointmentService.available(appointmentId).then((response: any) => {
        if (response.appointmentStatus != 'ready') {
          if (response.data.ready_in >= 1) {
            this.showAppointmentCountdownDialog(response.data.ready_in);
          } else {
            this.checkAppointmentStatusTimeout = setTimeout(() => {
              this.checkAppointmentStatus(appointmentId);
            }, 1000);
          }
        }
      });
    } catch (error: any) {
      if (error.appointmentStatus == 'ended') {
        this.showAppointmentEndedDialog();
      }
    }
  }

  showAppointmentCountdownDialog(timeToStart: number) {
    this.dialog.open(AppointmentCountdownComponent, {
      width: '80%',
      panelClass: 'custom-dialog-container',
      data: {
        message1: 'dialog_appointment_countdown1',
        message2: 'dialog_appointment_countdown2',
        message3: 'dialog_appointment_countdown3',
        time: timeToStart,
        title: 'dialog_title_warning',
      },
      disableClose: true,
    });
  }

  showAppointmentEndedDialog() {
    this.dialog.open(DialogComponent, {
      width: '80%',
      panelClass: 'custom-dialog-container',
      data: {
        message: 'dialog_appointment_error_ended',
        title: 'dialog_title_warning',
        canCancel: false,
      },
    });
  }

  redirectTo(path: string): void {
    this.ngZone.run(() => this.router.navigate([`/${AppPaths.CALL}`, path]));
  }
}
