import { Component, HostBinding, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationService } from 'src/app/services/registration.service';
import { ProviderService } from 'src/app/services/provider.service'
import { Patient } from 'src/app/models/patient.model';
import { PatientService } from 'src/app/services/patient.service';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { NavigationService } from 'src/app/services/navigation.service';
@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit {
  @HostBinding('class')
  hasConfetti = 'auth-confetti';

  acceptTerms = new FormControl(false, [Validators.required])
  termsAccepted = false;
  termsConditions: any;
  readOnly = false;
  patient!: Patient;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private patientService: PatientService,
    private providerService: ProviderService,
    private navigationService: NavigationService,
    private registrationService: RegistrationService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.patient = await this.patientService.getMe();
    if(!this.patient) this.router.navigate([`/${AppPaths.AUTH}`])

    this.readOnly = this.route.snapshot.paramMap.get('readOnly') === "true";
    this.termsAccepted = this.registrationService.hasAcceptedTyC(this.patient.id);
    if(!this.readOnly && this.termsAccepted) this.router.navigate([AppPaths.WARNING])

    let termsBody = await this.providerService.getTermsAndConditions(this.patient.provider.id);
    this.termsConditions = termsBody.html;
    if (this.readOnly) {
      this.navigationService.showBackButton();
    } else {
      this.navigationService.hideBackButton();
    }
  }

  continue(){
    this.registrationService.setTyC(this.patient.id);
    this.router.navigate([AppPaths.WARNING])
  }
}
