import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, Renderer2 } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

declare var gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  constructor(
    private router: Router,
    private renderer: Renderer2,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.registerIcons();
    this.insertAnalyticsScript();
  }

  ngAfterViewInit(): void {
    this.setUpAnalytics();
  }

  insertAnalyticsScript(): void {
    const script = this.renderer.createElement('script') as HTMLScriptElement;
    script.async = true;
    script.src =`https://www.googletagmanager.com/gtag/js?id=${environment.ANALYTICS_ID}`;
    this.renderer.appendChild(this.document.body, script);
  }

  setUpAnalytics() {
    const navEvents$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ) as Observable<NavigationEnd>;
    navEvents$.subscribe((event: NavigationEnd) => {
      gtag('config', environment.ANALYTICS_ID, {
        page_path: event.urlAfterRedirects
      });
    });
  }

  registerIcons(): void {
    this.matIconRegistry.addSvgIcon(
      'lad',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/lad.svg')
    ).addSvgIcon(
      'app_store',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/app_store.svg')
    ).addSvgIcon(
      'apple',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/apple.svg')
    ).addSvgIcon(
      'calls',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/calls.svg')
    ).addSvgIcon(
      'doctors',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/doctors.svg')
    ).addSvgIcon(
      'facebook',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/facebook.svg')
    ).addSvgIcon(
      'google',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/google.svg')
    ).addSvgIcon(
      'heart',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heart.svg')
    ).addSvgIcon(
      'home',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/home.svg')
    ).addSvgIcon(
      'instagram',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/instagram.svg')
    ).addSvgIcon(
      'linkedin',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/linkedin.svg')
    ).addSvgIcon(
      'outlook',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/outlook.svg')
    ).addSvgIcon(
      'play_store',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/play_store.svg')
    ).addSvgIcon(
      'questions',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/question.svg')
    ).addSvgIcon(
      'settings',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/settings.svg')
    ).addSvgIcon(
      'user',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/icon_user.svg')
    ).addSvgIcon(
      'users',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/users.svg')
    ).addSvgIcon(
      'info',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/info.svg')
    ).addSvgIcon(
      'east',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/icon_east.svg')
    ).addSvgIcon(
      'arrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/arrow.svg')
    ).addSvgIcon(
      'medicine',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/medicine.svg')
    ).addSvgIcon(
      'baby',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/pedia.svg')
    ).addSvgIcon(
      'gine',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/gine.svg')
    ).addSvgIcon(
      'prescription',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/prescription.svg')
    ).addSvgIcon(
      'covid',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/covid.svg')
    ).addSvgIcon(
      'chair',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/chair.svg')
    ).addSvgIcon(
      'headphones',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/headphones.svg')
    ).addSvgIcon(
      'microphone',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/microphone.svg')
    ).addSvgIcon(
      'microphone_off',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/microphone_off.svg')
    ).addSvgIcon(
      'media',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/media.svg')
    ).addSvgIcon(
      'noise',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/noise.svg')
    ).addSvgIcon(
      'phone',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/phone.svg')
    ).addSvgIcon(
      'call_phone',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/call_phone.svg')
    ).addSvgIcon(
      'phone_calling',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/phone_calling.svg')
    ).addSvgIcon(
      'sound_off',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/sound_off.svg')
    ).addSvgIcon(
      'exclamation',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/exclamation.svg')
    ).addSvgIcon(
      'videocamera',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/videocamera.svg')
    ).addSvgIcon(
      'videocamera_off',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/videocamera_off.svg')
    ).addSvgIcon(
      'upload_documents',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/upload_documents.svg')
    ).addSvgIcon(
      'apron',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/apron.svg')
    ).addSvgIcon(
      'fav-heart-filled',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/fav-heart-filled.svg')
    ).addSvgIcon(
      'fav-heart-outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/fav-heart-outline.svg')
    ).addSvgIcon(
      'calls-filled',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/calls-filled.svg')
    ).addSvgIcon(
      'pill',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/pill.svg')
    ).addSvgIcon(
      'prescription-green',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/prescription_green.svg')
    ).addSvgIcon(
      'search',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/search.svg')
    ).addSvgIcon(
      'black_question',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/black_question.svg')
    );
  }
}
