<div class="content">
    <div class="background">
        <div class="gradient"></div>
        <app-background-image></app-background-image>
        <div class="logo">
            <app-logo></app-logo>
        </div>
    </div>
    <div class="registrationContent">
        <div class="data-validation">
            <app-data-validation (updatedData)=updateData($event)>
            </app-data-validation>
        </div>
    </div>
</div>
