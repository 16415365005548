import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  MatListOption,
  MatSelectionList,
  MatSelectionListChange,
} from '@angular/material/list';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AmplitudeEvents } from 'src/app/enums/amplitude-events';
import { AppPaths } from 'src/app/enums/app-paths.enum';

import { LocalePipe } from 'src/app/locale.pipe';
import { CallData } from 'src/app/models/call-data.model';
import { FamilyGroup } from 'src/app/models/family-group.model';
import { Patient } from 'src/app/models/patient.model';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { CallService } from 'src/app/services/call.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-select-reason',
  templateUrl: './select-reason.component.html',
  styleUrls: ['./select-reason.component.css'],
  providers: [LocalePipe],
})
export class SelectReasonComponent implements OnInit, OnDestroy {
  @ViewChild('reasonsList') reasonsList!: MatSelectionList;
  otherReasonControl = new FormControl('', [
    Validators.required,
    Validators.minLength(3),
    Validators.maxLength(250),
  ]);
  showInput = false;
  patient!: Patient;
  subscription: Subscription;
  steps!: number;
  currentStepIndex!: number;

  reasons: { id: number; reasonLocaleKey: string }[] = [
    { id: 1, reasonLocaleKey: 'reason_fever' },
    { id: 2, reasonLocaleKey: 'reason_cold/flu/breathingDifficulty' },
    { id: 3, reasonLocaleKey: 'reason_headache' },
    { id: 4, reasonLocaleKey: 'reason_earache' },
    { id: 5, reasonLocaleKey: 'reason_soreThroat/cough' },
    { id: 6, reasonLocaleKey: 'reason_abdominalPain' },
    { id: 7, reasonLocaleKey: 'reason_vomiting/diarrhea' },
    { id: 8, reasonLocaleKey: 'reason_other' },
  ];

  constructor(
    private router: Router,
    private localePipe: LocalePipe,
    private callService: CallService,
    private patientService: PatientService,
    public amplitudeService: AmplitudeService,
    private navigationService: NavigationService
  ) {
    this.subscription = this.callService.callData$.subscribe({
      next: ({ caller }: CallData) => {
        this.patient = caller;
      },
    });
  }

  ngOnInit(): void {
    this.navigationService.hideMenu();
    this.navigationService.hideOptions();
    if (!this.patient) {
      this.router.navigate([`/${AppPaths.NAVBAR}`, AppPaths.FAMILY_GROUP]);
      return;
    }

    this.otherReasonControl.disable();
    this.patientService
      .getFamilyGroup()
      .then(async ({ members }: FamilyGroup) => {
        const hasFamilyGroup = members.length > 1;
        this.steps = hasFamilyGroup ? 4 : 3;
        this.currentStepIndex = hasFamilyGroup ? 1 : 0;
        const provider = await this.patientService.getProvider();
        if (provider.requiresHealthInsuranceInformation) {
          this.steps++;
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  selectionChange($event: MatSelectionListChange): void {
    const option = $event.options[0];
    const value = option.value;
    if (value.reasonLocaleKey === 'reason_other') {
      this.otherReasonControl.reset();
      this.showInput = option.selected;

      option.selected
        ? this.otherReasonControl.enable()
        : this.otherReasonControl.disable();
    }
  }

  goToNextStep(): void {
    const selectedKeys = this.reasonsList.selectedOptions.selected.map(
      (option: MatListOption) => {
        return option.value.reasonLocaleKey as string;
      }
    );
    const selectedReasons = selectedKeys.filter( (key: string) => key !== 'reason_other')
    .map((key: string) => {
      return this.localePipe.transform(key) as string;
    });
    if (this.otherReasonControl.enabled) {
      selectedReasons.push(this.otherReasonControl.value);
    }
    this.amplitudeService.showEvent(AmplitudeEvents.PATIENT_SELECT_REASON, {
      callerName: selectedReasons,
    });
    this.callService.reason = selectedReasons;
    if (this.patient.provider.requiresHealthInsuranceInformation) {
      this.router.navigate([`/${AppPaths.NAVBAR}`, AppPaths.AFFILIATE_INFO]);
    } else {
      this.router.navigate([`/${AppPaths.NAVBAR}`, AppPaths.MEDICAL_HISTORY]);
    }
  }
}
