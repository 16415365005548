<mat-icon svgIcon="lad"></mat-icon>
<div class="container">
  <div class="title">
    <img class="company-logo" [src]="provider.image" alt="Provider logo">
  </div>
  <div class="card">
    <div class="message-container">
      <p>
        {{ provider.loginValidatorTextHeader }}
      </p>
      <mat-icon svgIcon="apron"></mat-icon>
      <mat-icon svgIcon="heart"></mat-icon>
    </div>
    <a
      color="primary"
      target="_blank"
      mat-flat-button
      [href]="redirectURI"
      [disabled]="!redirectURI">
      {{ "registration_go_to" | locale }} {{ provider.name }}
      <mat-icon svgIcon="east" class="east"></mat-icon>
    </a>
  </div>
</div>
