import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-content',
  templateUrl: './nav-content.component.html',
  styleUrls: ['./nav-content.component.css']
})
export class NavContentComponent implements OnInit {
  @Input()
  @HostBinding('class.confetti-sm')
  showConfettiInSmallScreen = true;
  @Input()
  @HostBinding('class.confetti-lg')
  showConfettiInLargeScreen = true;
  @Input()
  @HostBinding('style.background-position-y')
  backgroundYPositionSm = '1rem';

  constructor() { }

  ngOnInit(): void {
  }

}
