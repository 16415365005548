import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
} from 'rxjs/operators';
import { AttachmentFilter } from 'src/app/enums/attachment-filter.enum';
import { CallFilter } from 'src/app/models/call-filter.model';
import { Doctor } from 'src/app/models/doctor.model';
import { Specialty } from 'src/app/models/specialty.model';
import { LocalizedNamePipe } from 'src/app/pipes/localized-name.pipe';
import { DoctorService } from 'src/app/services/doctor.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-call-filters',
  templateUrl: './call-filters.component.html',
  styleUrls: ['./call-filters.component.css'],
  providers: [LocalizedNamePipe],
})
export class CallFiltersComponent implements OnInit {
  formGroup!: FormGroup;
  specialties: Specialty[] = [];
  filteredSpecialties: Observable<Specialty[]> = of([]);
  doctors: Doctor[] = [];
  filteredDoctors: Observable<Doctor[]> = of([]);
  maxDate = new Date();
  fileOptions = [
    {
      value: AttachmentFilter.ORDER,
      label: 'filter_by_order',
    },
    {
      value: AttachmentFilter.PRESCRIPTION,
      label: 'filter_by_prescription',
    },
    {
      value: AttachmentFilter.CONSTANCY,
      label: 'filter_by_constancy',
    },
    {
      value: AttachmentFilter.NO_ATTACHMENT,
      label: 'filter_by_no_attachment',
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private doctorService: DoctorService,
    private patientService: PatientService,
    private localizedNamePipe: LocalizedNamePipe,
    private matDialogRef: MatDialogRef<CallFiltersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CallFilter,
  ) {}

  get specialtyControl(): FormControl {
    return this.formGroup.get('specialty') as FormControl;
  }

  get doctorControl(): FormControl {
    return this.formGroup.get('doctor') as FormControl;
  }

  async ngOnInit(): Promise<void> {
    this.buildForm();
    this.subscribeToFormChanges();

    const patient = await this.patientService.getMe();
    const provider = patient.provider;
    this.specialties = provider.specialties;
    this.specialtyControl.setValidators(this.valueInArray(this.specialties));

    this.doctors = await this.doctorService.loadDoctors();
    this.doctorControl.setValidators(this.valueInArray(this.doctors));
  }

  buildForm(): void {
    this.formGroup = this.formBuilder.group({
      specialty: [this.data?.specialty],
      doctor: [this.data?.doctor],
      date: [this.data?.date],
      file: [this.data?.file],
    });
  }

  submitForm(): void {
    if (!this.formGroup.valid) {
      return;
    }
    this.matDialogRef.close(this.formGroup.value);
  }

  selectionChange(value: AttachmentFilter): void {
    if (value === AttachmentFilter.NO_ATTACHMENT) {
      this.fileControl.reset();
      this.fileControl.setValue([AttachmentFilter.NO_ATTACHMENT]);
    } else if (this.fileControl.value.includes(AttachmentFilter.NO_ATTACHMENT)) {
      this.fileControl.setValue(
        this.fileControl.value.filter(
          (item: AttachmentFilter) => item !== AttachmentFilter.NO_ATTACHMENT
        )
      );
    }
  }

  valueInArray(array: Doctor[] | Specialty[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value || value?.length === 0) {
        return null;
      } else if (typeof value === 'string') {
        return { invalidValue: true };
      }
      const indexOf = array.findIndex(
        (item: Doctor | Specialty) => item.id === value.id
      );
      return indexOf === -1 ? { invalidValue: true } : null;
    };
  }

  subscribeToFormChanges(): void {
    this.filteredSpecialties = this.valueChangesPipe(
      this.specialtyControl.valueChanges
    ).pipe(map((value: string) => this.filterSpecialties(value)));

    this.filteredDoctors = this.valueChangesPipe(
      this.doctorControl.valueChanges
    ).pipe(map((value: string) => this.filterDoctors(value)));
  }

  valueChangesPipe(stream: Observable<any>): Observable<any> {
    return stream.pipe(
      startWith(''),
      filter((value: string) => typeof value === 'string'),
      debounceTime(400),
      distinctUntilChanged()
    );
  }

  displayDoctorFn(doctor?: Doctor): string {
    return doctor ? doctor.name : '';
  }

  private filterDoctors(valueToSearch: string): Doctor[] {
    valueToSearch = valueToSearch.toLowerCase().trim();

    return this.doctors.filter((doctor: Doctor) =>
      doctor.name.toLowerCase().includes(valueToSearch)
    );
  }

  displaySpecialtyFn(specialty?: Specialty): string {
    return specialty ? this.localizedNamePipe.transform(specialty)! : '';
  }

  private filterSpecialties(valueToSearch: string): Specialty[] {
    valueToSearch = valueToSearch.toLowerCase().trim();

    return this.specialties.filter((specialty: Specialty) =>
      this.localizedNamePipe
        .transform(specialty)
        ?.toLowerCase()
        .includes(valueToSearch)
    );
  }

  get fileControl(): FormControl {
    return this.formGroup.get('file') as FormControl;
  }
}
