<div class="background">
    <div class="gradient"></div>
    <app-background-image class="background-image"></app-background-image>
    <div class="logo">
        <app-logo></app-logo>
    </div>
</div>
<div class="terms-and-conditions right-content">
    <app-header></app-header>
    <p class="title">{{"terms_and_conditions" | locale }}</p>
    <div class="terms-and-conditions-container">
        <div class="terms-content">
            <div class="terms-text"
              [innerHTML]="termsConditions"
              [ngClass]="{'terms-accepted': readOnly}">
            </div>

            <ng-container *ngIf="!readOnly">
              <mat-checkbox class="accept-terms" [formControl]="acceptTerms">{{"accept_terms" | locale}}</mat-checkbox>
              <button mat-flat-button class="submit-btn submit continue-button arrow-black"
              (click)="continue()"
              [disabled]="!acceptTerms.value">
                  {{"continue" | locale}}
                  <mat-icon svgIcon="east" class="east"></mat-icon>
              </button>
            </ng-container>
        </div>
    </div>
</div>
