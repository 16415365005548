import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { from, PartialObserver } from 'rxjs';
import { AmplitudeEvents } from 'src/app/enums/amplitude-events';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { FamilyGroup } from 'src/app/models/family-group.model';
import { Patient } from 'src/app/models/patient.model';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { CallService } from 'src/app/services/call.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-family-group',
  templateUrl: './family-group.component.html',
  styleUrls: ['./family-group.component.css'],
})
export class FamilyGroupComponent implements OnInit {
  familyGroupMembers: Patient[] = [];
  patient!: Patient;
  steps = 4;

  constructor(
    private router: Router,
    private callService: CallService,
    private patientService: PatientService,
    public amplitudeService: AmplitudeService,
    private navigationService: NavigationService
  ) {
    if (!callService.specialty && !callService.doctor) {
      this.router.navigate([`/${AppPaths.NAVBAR}`]);
    }
  }

  ngOnInit(): void {
    this.navigationService.hideMenu();
    this.navigationService.hideOptions();

    this.patientService.getMe().then(async (me: Patient) => {
      this.patient = me;
      this.callService.caller = me;
      const { provider } = me;
      if (provider.requiresHealthInsuranceInformation) {
        this.steps++;
      }
    });
    this.patientService.getFamilyGroup().then(({ members }: FamilyGroup) => {
      if (members.length === 1) {
        this.router.navigate([`/${AppPaths.NAVBAR}`, AppPaths.SELECT_REASON]);
      }
      this.familyGroupMembers = members;
    });
  }

  onSelectMember(patient: Patient): void {
    this.callService.caller = patient;
  }

  goToNextStep(): void {
    this.amplitudeService.showEvent(AmplitudeEvents.FAMILY_GROUP_CALLER,
      {
        name: this.callService.caller.name,
        age: this.callService.caller.age,
        email: this.callService.caller.email
      })
    this.router.navigate([`/${AppPaths.NAVBAR}`, AppPaths.SELECT_REASON])
  }

}
