export const environment = {
  production: true,
  ENVIRONMENT: 'production',
  AUTH0_DOMAIN: 'lad.auth0.com',//"lad-dev.auth0.com",
  AUTH0_CLIENTID: 'gtnFqtYCa3vTiVdQlYAl6GRSTEtuXqRh',//"zMLFMDUNHqgRpCQLIAmJhnNr3YtveRNw",
  AUTH0_REALM: 'Affiliates-Username-Password-Authentication',// 'Affiliates-Username-Password-Authentication',
  AUTH0_SCOPE: 'openid offline_access', //'openid offline_access',
  API_URL: 'https://api.llamandoaldoctor.com', //'https://dev.api.llamandoaldoctor.com'
  PWA_URL: 'https://beta.app.llamandoaldoctor.com', //'https://dev.v2.app.llamandoaldoctor.com',
  BITBUCKET_BUILD_NUMBER: "228",
  OPENTOK_API_KEY: '45634892', // 45772292
  callConfig: {
    DEFAULT_CALL_TIMEOUT_MS: 420000, //7 minutos
    MIN_MS_FOR_VALID_CALL: 180000, //3 minutos
  },
  AMPLITUDE_API_KEY: '5c8904226638c53e66f51d7cbd75f249',
  AMPLITUDE_SECRET_KEY: 'd761455f4ad1ffae3d2cd3333769c26a',
  FAQ_URL: "https://llamandoaldoctor.com/faqs_usuarios.html",
  LAD_CONTACT_EMAIL: "contacto@llamandoaldoctor.com", // contacto@llamandoaldoctor.com
  ANALYTICS_ID: "UA-88686723-4",
  rollbarConfig: {
    ROLLBAR_ACCESS_TOKEN: "dce2f36d801f4e329710cdf497a9533e",
    ROLLBAR_ENVIRONMENT: "production",
  }
};
