<div class="container">
    <app-nav-content>
        <h2 title>{{'home_title'|locale}}</h2>

        <div card class="card-content">
            <app-select-item *ngFor="let specialty of specialties"
                                [itemText]="(specialty | localizedName)!"
                                [itemSubtext]="specialty.subtext ? specialty.subtext : ''"
                                [color]="specialty.hexaColor"
                                [srcIcon]="specialty.icon"
                                (click)="specialtySelected(specialty)"></app-select-item>
        </div>
    </app-nav-content>
</div>
