import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { PatientService } from 'src/app/services/patient.service';
import { RegistrationService } from 'src/app/services/registration.service';
import { DialogComponent } from '../../dialog/dialog.component';

@Component({
  selector: 'app-data-validation-token',
  templateUrl: './data-validation-token.component.html',
  styleUrls: ['./data-validation-token.component.css']
})
export class DataValidationTokenComponent implements OnInit {

  constructor(private registrationService: RegistrationService,
    private patientService: PatientService,
    private router: Router,
    private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  async updateData(updatedPatient: any){
    var patient = this.registrationService.getPatient();
    var dataToUpdate: {[field: string]: any} = {};

    var birthDateUnix = updatedPatient.birthDate;
    var e164phone = updatedPatient.phone ? updatedPatient.phone.e164Number : undefined;

    //validate changes
    if (e164phone != this.getPhone(patient)) dataToUpdate.mobilePhone = e164phone;
    if (birthDateUnix != this.getBirthDate(patient)) dataToUpdate.birthDate = birthDateUnix;
    if (updatedPatient.gender != patient.gender) dataToUpdate.gender = updatedPatient.gender;
    if (updatedPatient.name && updatedPatient.name != patient.name) dataToUpdate.name = updatedPatient.name;
    if (updatedPatient.email && updatedPatient.email != patient.email) dataToUpdate.email = updatedPatient.email;

    try {
      await this.patientService.update(patient.id, dataToUpdate);
      this.registrationService.cleanData();
      this.router.navigate([AppPaths.TERMS_AND_CONDITIONS]);
    } catch (err:any) {
      if(err.status == 400 && err.type == "existingObject"){
        this.showEmailRepeatedDialog()
      } else {
        this.showCustomErrorDialog()
      }
    }
  }

  getBirthDate(patient:any){
    return patient.birthDate || patient.birthdate;
  }

  getPhone(patient:any){
    return patient.mobilePhone || patient.phone;
  }

  showEmailRepeatedDialog(){
    this.dialog.open(DialogComponent, {
      width:"80%",
      panelClass: 'custom-dialog-container',
      data: {
        message: "dialog_registration_email_already_exists",
        title: "dialog_title_warning",
        canCancel: true
      }
    })
  }

  showCustomErrorDialog(){
    this.dialog.open(DialogComponent, {
      width:"80%",
      panelClass: 'custom-dialog-container',
      data: {
        message: "dialog_data_validation_custom_error",
        title: "dialog_title_warning",
        canCancel: true
      }
    })
  }
}
