import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { LocaleService } from 'src/app/services/locale.service';
import { RegistrationService } from 'src/app/services/registration.service';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import 'moment/locale/es';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-data-validation',
  templateUrl: './data-validation.component.html',
  styleUrls: ['./data-validation.component.css'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'es-ar'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true }}
  ]
})
export class DataValidationComponent implements OnInit {

  genders = [
    { name: "gender_female", value: "F"},
    { name: "gender_male", value: "M"},
    { name: "gender_undefined", value: "X"}
  ];

  @Output() updatedData = new EventEmitter();

  dataFormGroup = new FormGroup({
    birthDate: new FormControl('', [Validators.required]),
    phone: new FormControl(undefined, [Validators.required]),
    gender: new FormControl('', [Validators.required])
  })

  patient: any;
  provider: any;

  providerLanguages: any = [];
  selectedLanguage: any;

  readonly searchCountryFields = [SearchCountryField.Iso2, SearchCountryField.Name];
  readonly phoneNumberFormat = PhoneNumberFormat.International;
	readonly preferredCountries: CountryISO[] = [CountryISO.Argentina, CountryISO.Brazil, CountryISO.UnitedStates];
  selectedCountry!: CountryISO;

  constructor(private registrationService: RegistrationService,
    private localeService: LocaleService,
    private router: Router,
    private _adapter: DateAdapter<any>) { }

  ngOnInit(): void {
    this.patient = this.registrationService.getPatient();
    this.provider = this.registrationService.getProvider();
    if(!this.patient || _.isEmpty(this.patient)) this.router.navigate([`/${AppPaths.AUTH}`])

    this.providerLanguages = this.provider.supportedLanguages;
    if (this.providerLanguages.length > 0) {
      this.selectedLanguage = this.providerLanguages[0];
    }
    this.selectedCountry = this.localeService.getCountryISO();
    this.dataFormGroup.patchValue({
      birthDate: this.getBirthDate(this.patient),
      phone: this.getPhone(this.patient),
      gender: this.patient.gender
    })
    this.dataFormGroup.markAllAsTouched();
    this.setDatePickerLocale();
    this.addDynamicFormControls();
  }

  continue(){
    const birthdateUnix = moment(this.dataFormGroup.get('birthDate')?.value).unix();
    this.updatedData.emit({...this.dataFormGroup.value, birthDate: birthdateUnix})
  }

  onLocaleChanged() {
    this.localeService.setLocaleInStorage(this.selectedLanguage.code, "local");
    this.localeService.setLocaleIdInStorage(this.selectedLanguage.id);
    this._adapter.setLocale(this.selectedLanguage.code);
  }

  addDynamicFormControls(){
    if(this.patient.provider.askAllFields){
      this.dataFormGroup.addControl("name", new FormControl('', [Validators.required]));
    }
    if(!this.patient.email){
      this.dataFormGroup.addControl("email", new FormControl('', [Validators.required, Validators.email]));
    }
  }

  getBirthDate(patient:any){
    var date = patient.birthDate || patient.birthdate;
    return moment.unix(date).toDate();
  }

  getPhone(patient:any){
    return patient.mobilePhone || patient.phone;
  }

  setDatePickerLocale(){
    this._adapter.setLocale(this.selectedLanguage.code)
  }

}
