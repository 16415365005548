<app-nav-content [showConfettiInSmallScreen]="false">
  <ng-container ngProjectAs="[title]">
    <app-stepper [numberOfSteps]="steps"></app-stepper>
    <h1 t>
      {{ 'family_group_selection' | locale}}
    </h1>
  </ng-container>
  <ng-container ngProjectAs="[card]">
    <app-stepper [numberOfSteps]="4"></app-stepper>
    <app-family-group-list
      [isSelectable]="true"
      [selectedMember]="patient"
      [members]="familyGroupMembers"
      (memberSelected)="onSelectMember($event)">
    </app-family-group-list>
    <button mat-flat-button color="primary" (click)="goToNextStep()">
      <span>{{ 'family_group_selection_button' | locale}}</span>
      <mat-icon svgIcon="east" class="east"></mat-icon>
    </button>
  </ng-container>
</app-nav-content>
<ng-template #notImage>
  <mat-icon mat-list-icon svgIcon="user"></mat-icon>
</ng-template>
