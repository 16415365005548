
<app-nav-content [ngClass]="{ 'has-favorites': favouritedDoctors.length > 0}">
  <ng-container ngProjectAs="[title]">
    <div class="search-options">
      <form [formGroup]="searchDoctorFormGroup" class="doctor-search-form">
        <mat-label class="search-doctor-input-label">
          {{ 'doctor_search_label_for_search' | locale}}
        </mat-label>
        <mat-form-field appearance="outline">
            <input
                matInput
                formControlName="doctor"
                [matAutocomplete]="auto"
                class="doctor-search-input"
                [placeholder]="'doctor_search_placeholder_for_search' | locale">
            <mat-autocomplete
                class="doctor-search-panel"
                #auto="matAutocomplete"
                [displayWith]="displayFn">
                <mat-option
                    [value]="doctor"
                    (click)="this.goToDoctorDetail(doctor)"
                    *ngFor="let doctor of filteredOptions | async; last as isLast">
                <div
                    class="doctor-search-option"
                    [ngClass]="{'not-last-option': !isLast}">
                    <img class="doctor-image" [src]="doctor.image || defaultImage">
                    <div class="doctor-details">
                      <span class="doctor-name">{{doctor.name}}</span>
                      <div class="specialty-container">
                        <span class="doctor-specialty">{{doctor.mainSpecialty |localizedName}}</span>
                        <span *ngIf="doctor.available && doctor.canCall && doctor.hasDevice && !doctor.banned && !doctor.doNotDisturb; else notAvailableBlock" class="doctor-availability available">
                          {{ 'doctor_search_available' | locale }}
                        </span>
                        <ng-template #notAvailableBlock>
                          <span class="doctor-availability not-available">
                            {{ 'doctor_search_not_available' | locale }}
                          </span>
                        </ng-template>
                      </div>
                      <div class="rating">
                        <div class="doctor-rating">{{ doctor.reputation | number: '0.1-1'  }}</div>
                        <div class="stars">
                          <ng-container *ngFor="let i of [1, 2, 3, 4, 5];">
                            <mat-icon class="doctor-score-icon">
                              {{ i <= (doctor.reputation | round) ? 'star' : 'star_border' }}
                            </mat-icon>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                    <div class="favourite-button">
                      <span *ngIf="doctor.favourited; else notFavoriteBlock">
                        <a (click)="removeFavoriteDoctor($event, doctor)">
                          <mat-icon svgIcon="fav-heart-filled" class="doctor-score-unfav-button">
                          </mat-icon>
                        </a>
                      </span>
                      <ng-template #notFavoriteBlock>
                        <a (click)="addFavoriteDoctor($event, doctor)">
                          <mat-icon svgIcon="fav-heart-outline" class="doctor-score-unfav-button">
                          </mat-icon>
                        </a>
                      </ng-template>

                    </div>

                </div>
                </mat-option>
            </mat-autocomplete>
            <mat-icon matPrefix color="accent">search_outlined</mat-icon>
        </mat-form-field>
      </form>
    </div>
    <div class="doctor-search-title">
      {{ 'doctor_search_title' | locale}}
    </div>
  </ng-container>
  <ng-container ngProjectAs="[card]">
    <div class="doctor-search-title">
      {{ 'doctor_search_title' | locale}}
    </div>
    <div class="card-gallery" *ngIf="favouritedDoctors.length > 0; else notFavorities">
        <div class="doctor-card" (click)="goToDoctorDetail(doctor)" *ngFor="let doctor of favouritedDoctors">
          <img class="doctor-card-photo" [src]="doctor.image || defaultImage">
          <div class="doctor-name">{{doctor.name}}</div>
          <p>
            {{ (doctor.mainSpecialty | localizedName) || ("doctor_default_specialty" | locale) }}</p>
          <div class="doctor-card-score">
            <span>{{doctor.reputation | number: '0.1-1' }}</span>
            <ng-container *ngFor="let i of [1, 2, 3, 4, 5];">
              <mat-icon class="doctor-score-icon">
                {{ i <= (doctor.reputation | round) ? 'star' : 'star_border' }}
              </mat-icon>
            </ng-container>
          </div>
          <button mat-icon-button (click)="removeFavoriteDoctor($event, doctor)">
            <mat-icon svgIcon="fav-heart-filled" class="doctor-score-unfav-button"></mat-icon>
          </button>
        </div>
    </div>
    <ng-template #notFavorities>
      <div class="no-favorite-doctors">
        <p>
          {{ 'have_not_marked_any_doctor_yet' | locale}}
        </p>
      </div>
    </ng-template>
  </ng-container>
</app-nav-content>

