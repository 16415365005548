<div class="content">
    <div class="background">
        <app-background-image></app-background-image>
        <div class="logo">
            <app-logo></app-logo>
        </div>
        <a faqButton mat-icon-button>
            <mat-icon svgIcon="questions"></mat-icon>
        </a>
    </div>
    <div class="warning-content">
        <app-header></app-header>
        <div class="warning-sticker">
            <div class="warning-text">
                <div>
                  <p class="sticker-title">
                    {{"emergency_phone_title" | locale}}
                  </p>
                  <p class="sticker-body" *ngIf="!provider?.emergencyCall">
                      {{"emergency_phone_1" | locale}}
                      {{ emergencyPhone }},
                      {{"emergency_phone_2" | locale}}
                      {{"emergency_phone_3" | locale}}
                  </p>
                  <p class="sticker-body" *ngIf="provider?.emergencyCall">
                      {{"emergency_phone_1" | locale}}
                      {{ provider.emergencyCall }},
                      {{"emergency_phone_2" | locale}}
                      {{"emergency_phone_4" | locale}}
                      {{ provider.name }}
                      {{"emergency_phone_3" | locale}}
                  </p>
                </div>
                <mat-icon svgIcon="exclamation"></mat-icon>
            </div>
        </div>
        <div class="button-container">
            <button mat-flat-button color="primary" (click)="continue()">
                {{"continue" | locale}}
                <mat-icon svgIcon="east" class="east"></mat-icon>
            </button>
        </div>
    </div>
</div>
