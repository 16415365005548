import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { PatientService } from 'src/app/services/patient.service';
import { RegistrationService } from 'src/app/services/registration.service';
import { DialogComponent } from '../../dialog/dialog.component';
import { SubscribeDialogComponent } from '../subscribe-dialog/subscribe-dialog.component';

@Component({
  selector: 'app-user-validation',
  templateUrl: './user-validation.component.html',
  styleUrls: ['./user-validation.component.css']
})
export class UserValidationComponent implements OnInit {

  validationFormGroup = new FormGroup({
    documentNumber: new FormControl('', [Validators.required]),
    reference: new FormControl('', [Validators.required])
  })

  provider: any;
  dialogRef: any;

  registrationErrorMessage!: string;
  continueDisabled!: boolean;


  constructor(private patientService: PatientService,
    private registrationService: RegistrationService,
    private dialog: MatDialog,
    private router: Router) { }

  ngOnInit(): void {
    this.provider = this.registrationService.getProvider();
    if (!this.provider) this.router.navigate([AppPaths.REGISTRATION])
  }

  async validateData() {
    this.registrationErrorMessage = "";
    this.continueDisabled = true;
    try {
      let documentNumber = this.validationFormGroup.get('documentNumber')?.value
      let reference = this.validationFormGroup.get('reference')?.value
      let patient = await this.patientService.validateRegistration(documentNumber, reference, this.provider.id)
      if (patient.hasLoggedIn) {
        this.showHasLoggedInMessage()
        this.continueDisabled = false;
      } else {
        this.registrationService.setPatient(patient)
        this.nextStep();
      }
    } catch (err:any) {
      let invalidData = err.error?.status == 404;
      if (invalidData){
        this.registrationErrorMessage = "registration_error_data_mismatch";
        setTimeout(() => {
          this.openInvitationToSubscribe();
          this.registrationErrorMessage = "";
          this.continueDisabled = false;
        }, 1500)
      } else {
        this.showLoginErrorMessage()
        this.continueDisabled = false;
      }
    }
  }

  openInvitationToSubscribe() {
    this.dialog.open(SubscribeDialogComponent, {
      width: '80%',
      maxWidth: '350px',
      panelClass: 'custom-dialog-container',
    })
  }

  showHasLoggedInMessage() {
    let self = this;
    this.dialog.open(DialogComponent, {
      width: '80%',
      maxWidth: '350px',
      panelClass: 'custom-dialog-container',
      data: {
        message: "dialog_already_registered",
        title: "dialog_title_warning",
        canCancel: true,
        actions: [{
            message: "dialog_redirect_login",
            action: () => this.router.navigate([AppPaths.SOCIAL])
          }]
      },
      disableClose: true
    })
  }

  showLoginErrorMessage() {
    this.dialog.open(DialogComponent, {
      width: "80%",
      panelClass: 'custom-dialog-container',
      data: {
        message: "dialog_registration_custom_error",
        title: "dialog_title_warning",
        canCancel: true
      }
    })
  }

  nextStep() {
    this.router.navigate([AppPaths.REGISTRATION, AppPaths.ACCOUNT])
  }

  redirectToExplanation(){
    this.router.navigate([AppPaths.REGISTRATION, AppPaths.VALIDATOR_EXPLANATION])
  }

}
