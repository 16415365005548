import { Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LocalePipe } from 'src/app/locale.pipe';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { DoctorService } from 'src/app/services/doctor.service';
import { PatientService } from 'src/app/services/patient.service';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { AmplitudeEvents } from 'src/app/enums/amplitude-events';
import * as _ from 'lodash';
import { NavigationService } from 'src/app/services/navigation.service';
import { Doctor } from 'src/app/models/doctor.model';

@Component({
    selector: 'app-doctor-search',
    templateUrl: './doctor-search.component.html',
    styleUrls: ['./doctor-search.component.css'],
    providers: [LocalePipe],
  })
  export class DoctorSearchComponent implements OnInit, OnDestroy {

    searchDoctorFormGroup: FormGroup;
    favouritedDoctors: Doctor[] = [];
    selectedDoctor: any;
    filteredOptions: Observable<any[]> = new Observable();
    defaultImage = 'assets/doctor_default.png'
    doctorLimit = 25;

    constructor(
      private router: Router,
      private doctorService: DoctorService,
      private patientService: PatientService,
      public amplitudeService: AmplitudeService,
      public navigationService: NavigationService,
    ) {
      this.searchDoctorFormGroup = new FormGroup({
        doctor: new FormControl('')
      })
    }

    displayFn(option: { name: string; }): string {
      return option.name;
    }

    async ngOnInit(): Promise<void> {
      this.navigationService.showMenu();
      this.navigationService.showOptions;
      this.navigationService.hideBackButton();
      this.favouritedDoctors = await this.patientService.getFavouritedDoctors();
      this.filteredOptions = this.searchDoctorFormGroup.get('doctor')!.valueChanges
      .pipe(
        tap(() => {this.selectedDoctor = false}),
        startWith(''),
        filter(query => typeof(query) == "string" && query.length > 2),
        debounceTime(400),
        distinctUntilChanged(),
        switchMap(query => this.doctorService.searchDoctors(query, this.doctorLimit)),
        map(doctorsArray =>
          doctorsArray.map(
            doctor => ({
              ...doctor,
              favourited: this.favouritedDoctors.some((favouritedDoctor: { id: any; }) => doctor.id == favouritedDoctor.id)
            })
          )
        ),
      )
    }

    goToDoctorDetail(doctor: any = null): void {
      this.router.navigate([AppPaths.DOCTOR_DETAIL+"/"+doctor.id])
    }

    async addFavoriteDoctor(event: any, doctor: any) {
      event.preventDefault();
      event.stopPropagation();
      doctor.favourited = !doctor.favourited;
      await this.patientService.addDoctorToFavourites(doctor.id);
      this.amplitudeService.showEvent(AmplitudeEvents.PATIENT_FAV_DOCTOR, {
        doctor: `${doctor.firstName} ${doctor.lastName}`,
        doctorId: doctor.id
      })
      this.favouritedDoctors.splice(_.sortedIndexBy(this.favouritedDoctors, doctor, 'name'), 0, doctor);
    }

    async removeFavoriteDoctor(event: any, doctor: any) {
      event.preventDefault();
      event.stopPropagation();
      doctor.favourited = !doctor.favourited;
      await this.patientService.removeDoctorFromFavourites(doctor.id);
      this.amplitudeService.showEvent(AmplitudeEvents.PATIENT_REMOVE_FAV_DOCTOR, {
        doctor: `${doctor.firstName} ${doctor.lastName}`,
        doctorId: doctor.id
      })
      if(this.favouritedDoctors && this.favouritedDoctors.length > 0) {
        _.remove(this.favouritedDoctors, function(item:any) { return item.id == doctor.id } );
      }
    }

    ngOnDestroy(): void {
      this.navigationService.showBackButton();
    }
  }
