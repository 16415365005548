import { Component, HostBinding, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { AppointmentService } from 'src/app/services/appointment.service';
import { AuthService } from 'src/app/services/auth.service';
import { DialogComponent } from '../../dialog/dialog.component';
import { PatientService } from 'src/app/services/patient.service';
import { AmplitudeEvents } from 'src/app/enums/amplitude-events';
import * as moment from 'moment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CallService } from 'src/app/services/call.service';
import { Subscription } from 'rxjs';

declare var LukesLazyLoader: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  @HostBinding('class')
  hasConfetti = 'auth-confetti';
  @HostBinding('class.loaded')
  loaded = true;
  private subscription!: Subscription;

  socialPath = AppPaths.SOCIAL;
  registrationPath = AppPaths.REGISTRATION;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private authService: AuthService,
    private callService: CallService,
    public jwtHelper: JwtHelperService,
    private patientService: PatientService,
    public amplitudeService: AmplitudeService,
    private appointmentService: AppointmentService
  ) {}

  ngOnInit(): void {
    //Dejar la logica de login de token en este componente o moverla a un service?
    this.subscription = this.route.queryParams.subscribe((params) => {
      if (params.token) {
        this.loaded = false;
        localStorage.clear();
        var validAuthentication = this.authService.validateAuthentication(
          params.token
        );
        if (validAuthentication) {
          const shortFlow = params.shortFlow === 'true' || false;
          this.loginByToken(
            params.token,
            params.appointment,
            shortFlow,
            params.specialty
          );
        } else {
          this.loaded = true;
          this.openSessionExpiredDialog(params.token, params.appointment);
        }
      } else if (this.authService.isAuthenticated()) {
        this.router.navigate([AppPaths.TERMS_AND_CONDITIONS]);
      }
    });
  }

  ngOnDestroy():void  {
    this.subscription.unsubscribe();
  }

  private async loginByToken(
    token: string,
    appointmentId?: string,
    shortFlow?: boolean,
    specialtyRealId?: string
  ) {
    await this.authService.handleTokenLogin(token, appointmentId);

    const me = await this.patientService.getMe();
    this.amplitudeService.setUser(me.id);
    this.amplitudeService.showEvent(AmplitudeEvents.LOGIN_TOKEN, { token });
    const provider = me.provider;
    if (provider.customStyleSheetUrl) {
      this.loadCustomCSS(provider.customStyleSheetUrl);
    } else {
      this.loaded = true;
    }
    if (appointmentId) {
      var appointment = await this.appointmentService.validateAppointment(
        appointmentId
      );
      if (!appointment.valid) {
        this.openAppointmentErrorDialog(appointment.message);
      } else {
        this.appointmentService.setAppointment(appointmentId);
        this.router.navigate([AppPaths.DATA_VALIDATION]);
      }
    } else {
      if (shortFlow) {
        const providerSpecialties = (await this.patientService.getProvider())
          .specialties;
        let specialty = providerSpecialties.find(
          (s) => s.realId == specialtyRealId
        );
        if (!specialty) {
          specialty = providerSpecialties[0];
        }
        this.callService.specialty = specialty;
        localStorage.setItem('isShortFlow', 'true');
        this.router.navigate([`/${AppPaths.NAVBAR}`, AppPaths.FAMILY_GROUP]);
      } else {
        // Send token patient to registration second every time they log in.
        this.router.navigate([AppPaths.DATA_VALIDATION]);
      }
    }

  }

  openSessionExpiredDialog(token: string, appointment?: string) {
    var appointmentMessage;
    if (appointment) appointmentMessage = this.getAppointmentMessage(token);
    var message = appointmentMessage || 'dialog_error_token_expired';
    this.dialog.open(DialogComponent, {
      width: '80%',
      maxWidth: '350px',
      panelClass: 'custom-dialog-container',
      data: {
        message: message,
        title: 'dialog_title_warning',
        canCancel: false,
      },
      disableClose: true,
    });
  }

  openAppointmentErrorDialog(message: string) {
    this.dialog.open(DialogComponent, {
      width: '80%',
      maxWidth: '350px',
      panelClass: 'custom-dialog-container',
      data: {
        message: message,
        title: 'dialog_title_warning',
        canCancel: false,
      },
      disableClose: true,
    });
  }

  getAppointmentMessage(token: string) {
    var decodedToken = this.jwtHelper.decodeToken(token);
    var notBefore = moment.unix(Number(JSON.stringify(decodedToken.nbf)));
    if (moment().isBefore(notBefore)) {
      return 'dialog_appointment_error_not_started';
    }
    return 'dialog_appointment_error_ended';
  }

  loadCustomCSS(customStyleSheetUrl: string): void {
    LukesLazyLoader.load(
      { url: customStyleSheetUrl, type: 'css', args: { async: true } }, () => {
        console.log('custom.css loaded');
        this.loaded = true;
      }
    );
  }
}
