<div class="top-content">
  <img [src]="doctor.image || defaultImage">
  <div class="top-content-text">
    <h1 class="name">
      {{ doctor?.name }}
    </h1>
    <p class="specialty br-30">
      {{ specialty | localizedName }}
    </p>
    <ng-container *ngIf="!isAlwaysAvailable">
      <button
        mat-button
        *ngIf="showSchedules"
        (click)="openDoctorSchedulesDialog()">
        {{ 'see_doctor_schedules' | locale }}
      </button>
    </ng-container>

    <p class="rate">
      <span class="rate-value">
        {{ doctor.reputation }}
      </span>
      <span class="rate-stars">
        <mat-icon *ngFor="let i of [1, 2, 3, 4, 5]">
          {{ i <= (doctor.reputation | round) ? 'star' : 'star_border' }}
        </mat-icon>
      </span>
    </p>

    <ng-container *ngIf="isAlwaysAvailable">
      <p class="always-available">
        {{ 'doctor_always_available' | locale }}
      </p>
    </ng-container>
  </div>
</div>
<div class="bottom-content">
  <div class="curriculum">
    <p>
      {{ doctor.cv }}
    </p>
  </div>
  <ng-content select="[action]"></ng-content>
</div>
