import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  @HostBinding('class')
  hasConfetti = 'auth-confetti';

  constructor() { }

  ngOnInit(): void {
  }

}
