import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor() { }

  setProvider(provider:any){
    localStorage.setItem('provider', JSON.stringify(provider));
  }
  getProvider(){
    var provider = localStorage.getItem('provider');
    return provider ? JSON.parse(localStorage.getItem('provider')!) : "";
  }
  setPatient(patient:any){
    localStorage.setItem('patient', JSON.stringify(patient));
  }
  getPatient(){
    var patient = localStorage.getItem('patient');
    return patient ? JSON.parse(localStorage.getItem('patient')!) : "";
  }
  updatePatient(updateBody: Object){
    var patient = localStorage.getItem('patient');
    if (!patient) return;
    var parsedPatient = JSON.parse(localStorage.getItem('patient')!);
    parsedPatient = {... parsedPatient, ...updateBody}
    localStorage.setItem('patient', JSON.stringify(parsedPatient));
  }
  setTyC(patientId: string){
    sessionStorage.setItem('acceptedT&C_' + patientId, "true");
  }
  hasAcceptedTyC(patientId: string){
    return sessionStorage.getItem('acceptedT&C_' + patientId) == "true";
  }
  cleanData(){
    localStorage.removeItem('patient')
    localStorage.removeItem('provider')
  }
}
