<button mat-icon-button (click)="switchCamera()">
  <mat-icon>flip_camera_ios</mat-icon>
</button>
<div
  class="doctor-info animated"
  *ngIf="this.filesUploading.length == 0"
  [ngClass]="{'fade-in': fadeInDoctorInfo, 'fade-out': fadeOutDoctorInfo}">
  <h1 class="name">
    {{ doctor?.name }}
  </h1>
  <p class="specialty">
    {{ specialty | localizedName }}
  </p>
  <p *ngIf="doctor" class="rate">
    <span class="rate-value">
      {{ doctor.reputation }}
    </span>
    <span class="rate-stars">
      <mat-icon *ngFor="let i of [1, 2, 3, 4, 5]">
        {{ i <= doctor.reputation ? 'star' : 'star_border' }}
      </mat-icon>
    </span>
  </p>
  <button
    mat-flat-button
    color="primary"
    (click)="showLoadedFiles()"
    *ngIf="filesUploaded.length > 0">
    {{ filesUploaded.length }} {{ 'files' | locale }}
  </button>
</div>
<div class="file-list">
  <template #fileListContainer></template>
</div>
<div
  #subscriber
  id="subscriber"
  class="subscriber"
  (click)="fadeInDoctorInfoAnimation()"></div>
<div
  #publisher
  id="publisher"
  class="publisher"></div>
<app-call-options
  #callOptions
  [floatingButton]="enableFileSharing"
  [videocamera]="(camera$ | async)!"
  [microphone]="(microphone$ | async)!"
  (hangout)="endCall()"
  (fileToUpload)="uploadFile($event)"
  (microphoneChange)="toggleMicrophone()"
  (videocameraChange)="toggleCamera()">
</app-call-options>
