import { Platform } from '@angular/cdk/platform';
import { Component, OnInit } from '@angular/core';
import { Provider } from 'src/app/models/provider.model';
import { RegistrationService } from 'src/app/services/registration.service';

@Component({
  selector: 'app-token-income',
  templateUrl: './token-income.component.html',
  styleUrls: ['./token-income.component.css']
})
export class TokenIncomeComponent implements OnInit {
  provider!: Provider;
  redirectURI!: string;

  constructor(
    private platform: Platform,
    private registrationService: RegistrationService
  ) { }

  ngOnInit(): void {
    this.provider = this.registrationService.getProvider();
    if (this.platform.ANDROID) {
      this.redirectURI = `market://details?id=${this.provider.androidPackageName}`;
    } else if (this.platform.IOS) {
      this.redirectURI = `itms-apps://itunes.apple.com/app/id${this.provider.iosBundleId}`;
    } else if (this.provider.webUrl) {
      this.redirectURI = this.provider.webUrl;
    }
  }

}
