<h1 mat-dialog-title>{{ data.title | locale}}</h1>
<div mat-dialog-content>
  <p>{{ data.message | locale}}</p>
</div>
<div mat-dialog-actions>
    <button
      color="primary"
      mat-stroked-button
      *ngIf="data.canCancel"
      (click)="cancel()">
      {{"dialog_close" | locale}}
    </button>
    <button
      color="primary"
      mat-flat-button
      cdkFocusInitial
      *ngFor="let button of data.actions;"
      (click)="execute(button.action)">
      {{button.message | locale}}
    </button>
</div>
