import { environment } from "src/environments/environment";

const { ROLLBAR_ACCESS_TOKEN, ROLLBAR_ENVIRONMENT } = environment.rollbarConfig;

export const ROLLBAR_CONFIG = {
  accessToken: ROLLBAR_ACCESS_TOKEN,
  environment: ROLLBAR_ENVIRONMENT,
  captureUncaught: true,
  captureUnhandledRejections: true,
};
