import { Component, OnInit } from '@angular/core';
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { PatientService } from 'src/app/services/patient.service';
import { AmplitudeEvents } from 'src/app/enums/amplitude-events';
import { MatDialog } from '@angular/material/dialog';
import { CallFiltersComponent } from '../call-filters/call-filters.component';
import { Call } from 'src/app/models/call.model';
import { CallFilter } from 'src/app/models/call-filter.model';
import * as moment from 'moment';

@Component({
  selector: 'app-calls-history',
  templateUrl: './calls-history.component.html',
  styleUrls: ['./calls-history.component.css'],
})
export class CallsHistoryComponent implements OnInit {
  calls: Call[] = [];
  currentPage = 0;
  detailsPath: string = `/${AppPaths.NAVBAR}/${AppPaths.CALL_DETAIL}/`;
  resultFilter!: CallFilter;

  constructor(
    private matDialog: MatDialog,
    private patientService: PatientService,
    private amplitudeService: AmplitudeService
  ) {}

  ngOnInit(): void {
    this.amplitudeService.showEvent(AmplitudeEvents.PATIENT_CALL_HISTORY, {});
    this.getCalls();
  }

  async getCalls() {
    this.calls = await this.patientService.getCallsHistory();
  }

  openCallFilters(): void {
    const dialogRef = this.matDialog.open(CallFiltersComponent, {
      panelClass: 'filter-dialog-container',
      data: this.resultFilter,
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.currentPage = 0;
        Object.keys(result).forEach((key) => !result[key] ? delete result[key] : {});
        this.resultFilter = result;
        result = {
          ...result,
          specialty: result.specialty?.realId,
          doctor: result.doctor?.id,
        }
        if (result.date) {
          result = moment(result.date).format("DD-MM-YYYY")
        }
        this.calls = await this.patientService.getCallsHistory(result);
      }
    });
  }
}
