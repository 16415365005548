<app-nav-content [showConfettiInSmallScreen]="false">
  <ng-container ngProjectAs="[title]">
    <app-stepper [numberOfSteps]="!hasFamilyGroup ? 4 : 5" [currentStepIndex]="!hasFamilyGroup ? 1 : 2"></app-stepper>
    <h2 class="patient">
      {{ 'reason_patient' | locale }}
      <span class="patient-name">
        {{ patient?.name}}
      </span>
    </h2>
    <h1>
      {{ 'affiliate_title' | locale}}
    </h1>
  </ng-container>
  <ng-container ngProjectAs="[card]">
    <app-stepper [numberOfSteps]="!hasFamilyGroup ? 4 : 5" [currentStepIndex]="!hasFamilyGroup ? 1 : 2"></app-stepper>
    <form [formGroup]="formGroup">
      <mat-form-field class="autocomplete-container">
        <mat-icon
          matPrefix
          color="accent"
          svgIcon="search">
        </mat-icon>
        <input
          matInput
          type="text"
          formControlName="healthInsurance"
          [matAutocomplete]="insuranceAuto"
          [placeholder]="'affiliate_search_placeholder' | locale">
          <mat-autocomplete
            class="custom-panel"
            #insuranceAuto="matAutocomplete">
            <mat-option
              [value]="healthInsurance.name"
              *ngFor="let healthInsurance of (healthInsurances$ | async)">
              {{ healthInsurance.name }}
            </mat-option>
          </mat-autocomplete>
      </mat-form-field>
      <div [ngClass]="{'d-none': displayNone}">
        <mat-label>
          {{ 'affiliate_number' | locale }}
          <span>*</span>
        </mat-label>
        <mat-form-field>
          <input
            matInput
            type="text"
            formControlName="credential"
            [placeholder]="'affiliate_number_placeholder' | locale"
            [attr.aria-label]="'affiliate_number_placeholder' | locale">
        </mat-form-field>
      </div>
    </form>
    <button
      type="button"
      mat-flat-button
      color="primary"
      (click)="submitForm()"
      [disabled]="!formGroup?.valid">
      {{ 'continue' | locale}}
      <mat-icon svgIcon="east" class="east"></mat-icon>
  </button>
  </ng-container>
</app-nav-content>
